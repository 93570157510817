<template>
  <div>
    <sidebar-ownership
      :idsite="siteObj && siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open.sync="showOpen"
    />
    <edit-site-domain
      :idsite="siteObj && siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-site-domain.sync="showOpenSiteDomain"
    />
    <edit-site-name
      :idsite="siteObj && siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-site-name.sync="showOpenSiteName"
    />
    <activate-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenActiveSite"
    />
    <archive-site
      :idsite="idsite"
      :show-open-archive-site.sync="showOpenArchiveSite"
    />
    <coming-soon
      :idsite="siteObj && siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-coming-soon.sync="showOpenComingSoon"
    />
    <sidebar-pro-pages
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-pro-pages.sync="showOpenSidebarProPages"
      :data-propages-sidebar="listPagesSidebars"
      :crawls="crawls"
      :crawl-running="crawlRunning"
      :prolist.sync="sitesProPages"
    />
    <info-license
      :idsite="infoLicense"
      :user-data.sync="userData"
      :show-open-info-license.sync="showOpenInfoLicense"
    />
    <div>
      <b-overlay
        :show="siteObj.length === 0"
        rounded="xl"
        no-fade
      >
        <div
          class="row content-header"
          style="margin-left: 3px"
        >
          <b-container
            class="bv-example-row bv-example-row-flex-cols pl-0 ml-0"
            style="width: 100%; max-width: none;"
          >
            <b-row class="pl-0 ml-0 row-xl-container-edit-site">
              <b-row style="width: 220px; padding-right: 5px;" class="row-container-img">
                <b-col
                  md="12"
                  align-self="baseline"
                  style="margin-right: 5px;"
                >
                  <b-nav-item-dropdown
                    id="dropdown-edit-site-id"
                    ref="dropdown"
                    right
                    toggle-class="d-flex align-items-center dropdown-user-link nav-link-custom"
                    class="dropdown-edit-site pb-75"
                  >
                    <template #button-content>
                      <b-img-lazy
                        :src="siteObj[0].imgSite"
                        :alt="'img not found'"
                        style="width: 175px; height: 168px; object-fit: contain; margin-top: 0px !important; padding-top: 0px; position: relative; top: -11px;"
                        class="mt-1"
                        onerror="this.onerror=null;this.src='https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b';"
                      />
                    </template>
                    <ul class="dropdown-menu dropdown-menu-right" style="top: -120px !important; left: -63px !important;">
                      <b-dropdown-item
                        :href="siteObj[0].domain"
                        link-class="d-flex align-items-center item-dropdown-user"
                        style="font-size: 13px;"
                        target="_blank"
                      >
                        <span>Open in new tab</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click.prevent="getError"
                        link-class="d-flex align-items-center item-dropdown-user"
                        style="font-size: 13px;"
                      >
                        <span>Fix errors</span>
                      </b-dropdown-item>
                    </ul>
                  </b-nav-item-dropdown>
                  <!-- </b-link> -->
                </b-col>
              </b-row>
              <b-row style="width: 390px; padding-right: 5px;" class="row-container-domain-name">
                <b-col
                  md="12"
                  align-self="baseline"
                  style="margin-right: 5px;"
                >
                  <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="card-domain-site-name">
                    <b-card-text>
                      <template>
                        <div
                          class="text-nowrap"
                          style="display: block;"
                        >
                          <template>
                            <b-button
                              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                              variant="flat-warning"
                              style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                              class="btn-icon rounded-circle"
                            >
                              <b-img-lazy
                                :src="imgSite"
                                alt="logo"
                              />
                              <!-- <b-icon icon="building" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              class="btn-icon rounded-circle float-right ml-25 eyeBtn"
                              size="sm"
                              style="background: #F9F9F9 !important; border: 1px solid #F9F9F9 !important; width: 24px; height: 24px; padding: 0.1rem;"
                              target="_blank"
                              :href="siteObj[0].domain"
                            >
                              <feather-icon
                                icon="ExternalLinkIcon"
                                class="p-0 ml-0 eyeBtn"
                              />
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              class="btn-icon rounded-circle float-right"
                              size="sm"
                              style="background: #F9F9F9 !important; border: 1px solid #F9F9F9 !important; width: 24px; height: 24px; padding: 0.1rem; z-index: 11; position: relative;"
                              @click.prevent="logIdSiteName()"
                            >
                              <feather-icon
                                icon="Edit2Icon"
                                style="color: #A7AAB9; fill: #A7AAB9;"
                                class="p-0 ml-0"
                              />
                            </b-button>
                            <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                              <span
                                style="color: #A7AAB9; font-size: 16px;"
                                class=""
                              >Site Name</span>
                              <span
                                style="font-weight: 700; width: 100%; font-size: 15px; color: #656B85; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden; display: block;"
                                class=""
                              >{{ siteObj[0].name }}</span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col
                  md="12"
                  align-self="baseline"
                  class="margin-right: 5px;"
                >
                  <b-link
                    style="font-size: 16px; text-decoration: none; color: #7838ff;"
                    :href="siteObj[0].domain"
                    target="_blank"
                  >
                    <b-card style="height: 74px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="card-domain-site-name">
                      <b-card-text>
                        <template>
                          <div
                            class="text-nowrap"
                            style="display: block;"
                          >
                            <template>
                              <b-button
                                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                variant="flat-warning"
                                style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                class="btn-icon rounded-circle"
                              >
                                <b-img-lazy
                                  :src="imgDomain"
                                  alt="logo"
                                />
                                <!-- <b-icon icon="building" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                class="btn-icon rounded-circle float-right ml-25 eyeBtn"
                                size="sm"
                                style="background: #F9F9F9 !important; border: 1px solid #F9F9F9 !important; width: 24px; height: 24px; padding: 0.1rem;"
                                target="_blank"
                                :href="siteObj[0].domain"
                              >
                                <feather-icon
                                  icon="ExternalLinkIcon"
                                  class="p-0 ml-0 eyeBtn"
                                />
                              </b-button>
                              <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                                <span
                                  style="color: #A7AAB9; font-size: 16px;"
                                  class=""
                                >Domain</span>
                                <span
                                  style="width: 100%; font-size: 15px; color: #656B85; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden; display: block;"
                                  class=""
                                >
                                  <b-link
                                    style="font-size: 16px; text-decoration: none; color: #656B85 !important;"
                                    :href="siteObj[0].domain"
                                    target="_blank"
                                  >
                                    <feather-icon
                                      icon="ExternalLinkIcon"
                                      class="p-0 ml-0 mr-0"
                                      style="margin-bottom: 7px !important; margin-left: 0px !important; color: #656B85;"
                                    />
                                    {{ siteObj[0].domainFix }}
                                  </b-link>
                                </span>
                              </div>
                            </template>
                          </div>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-link>
                </b-col>
              </b-row>

              <b-row style="width: 230px; padding-right: 5px;" class="row-no-container-domain-name">
                <b-col
                  md="12"
                  align-self="baseline"
                >
                  <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cards-no-domain-site-name">
                    <b-card-text>
                      <template>
                        <div
                          class="text-nowrap"
                          style="display: block;"
                        >
                          <template>
                            <b-button
                              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                              variant="flat-warning"
                              style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                              class="btn-icon rounded-circle"
                            >
                              <b-img-lazy
                                :src="imgKey"
                                alt="logo"
                              />
                              <!-- <b-icon icon="back" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                            </b-button>
                            <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                              <span
                                style="color: #A7AAB9; font-size: 16px;"
                                class=""
                              >Key</span>
                              <!-- <span style="width: 100%; font-size: 15px; color: #656B85;" class="">asdf</span> -->
                              <div style="display: flex;">
                                <span
                                  class=""
                                  v-if="!keyVisible"
                                  style="font-size: 14px; color: #656B85;"
                                >••••••••{{ siteObj[0].key.length === 21 ? siteObj[0].key.slice(19) : siteObj[0].key.slice(20) }}</span>
                                <span
                                  v-else
                                  class=""
                                  style="font-size: 14px; color: #656B85;"
                                >{{ keyID }}</span>
                                <b-button
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="flat-dark"
                                  style="color: #A7AAB9; width: 24px; height: 24px; padding: 0.1rem;"
                                  class="btn-icon rounded-circle ml-1"
                                  @click.prevent="toggleVisibleKey"
                                >
                                  <feather-icon icon="EyeIcon" v-if="!keyVisible" />
                                  <feather-icon icon="EyeOffIcon" v-if="keyVisible" />
                                </b-button>
                                <b-button
                                  v-clipboard:copy="keyID"
                                  v-clipboard:success="onCopy"
                                  v-clipboard:error="onError"
                                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                  variant="flat-dark"
                                  style="color: #A7AAB9; width: 24px; height: 24px; padding: 0.1rem;"
                                  class="btn-icon rounded-circle float-right"
                                >
                                  <feather-icon icon="CopyIcon" />
                                </b-button>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col
                  md="12"
                  align-self="baseline"
                >
                  <b-card no-body style="height: 74px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cards-no-domain-site-name">
                    <b-card-text style="margin: auto 25px;">
                      <template>
                        <div
                          class=""
                          style=""
                        >
                          <template>
                            <b-button
                              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                              variant="flat-warning"
                              style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                              class="btn-icon rounded-circle"
                            >
                              <b-img-lazy
                                :src="imgOwned"
                                alt="logo"
                              />
                              <!-- <b-icon icon="back" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                            </b-button>
                            <div style="width: 50%; display: inline-block; margin-left: 10px; vertical-align: middle;">
                              <span
                                style="color: #A7AAB9; font-size: 14px;"
                                class=""
                              >Owned by</span>
                              <!-- <span style="font-weight: 700; width: 100%; font-size: 15px; color: #656B85;" class="">{{ siteObj[0].ownedBy }}</span> -->
                              <div v-if="userData.uid !== siteObj[0].ownedBy.uid || !siteObj[0].ownedBy || !siteObj[0].ownedBy.uid">
                                <div class="d-flex mb-50" v-if="(permission === 'owner' || permission === 'admin' || permission === 'edit' || permission === 'view') && siteObj[0].ownedBy.uid">
                                    <b-avatar
                                      size="27"
                                      :src="siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.foto : ''"
                                      variant="light-dark"
                                      class="badge-minimal"
                                      alt="img user"
                                      :text="avatarText(!siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.name : '')"
                                      style="background: #ddd !important;"
                                      v-if="userData.uid === siteObj[0].ownedBy.uid"
                                    >
                                        <feather-icon
                                          v-if="!siteObj[0].ownedBy.foto && !siteObj[0].ownedBy.name"
                                          icon="UserIcon"
                                          size="22"
                                        />
                                    </b-avatar>

                                    <div class="ml-25 mt-50">
                                      <p class="mb-0" style="font-size: 13px; line-height: 14px; color: #656B85; cursor: initial !important;" v-if="userData.uid === siteObj[0].ownedBy.uid">
                                        {{ siteObj[0].ownedBy.name.substring(0, 15) + (siteObj[0].ownedBy.name.length > 15 ? '...' : '') }}
                                      </p>

                                      <b-dropdown
                                        :text="siteObj[0].membership"
                                        variant="outline-secondary"
                                        class="style-chooser cursor-pointer"
                                        :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' || permission === 'view' ? false : true"
                                        v-else
                                      >
                                        <template #button-content>
                                          <b-avatar
                                            size="27"
                                            :src="siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.foto : ''"
                                            variant="light-dark"
                                            class="badge-minimal"
                                            alt="img user"
                                            :text="avatarText(!siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.name : '')"
                                            style="background: #ddd !important;"
                                          >
                                            <feather-icon
                                              v-if="!siteObj[0].ownedBy.foto && !siteObj[0].ownedBy.name"
                                              icon="UserIcon"
                                              size="22"
                                            />
                                          </b-avatar>
                                          <span class="ml-25 mt-50 mb-0" style="font-size: 13px; line-height: 14px; color: #656B85;">
                                            {{ siteObj[0].ownedBy.name.substring(0, 15) + (siteObj[0].ownedBy.name.length > 15 ? '...' : '') }}
                                          </span>
                                        </template>
                                        <b-dropdown-item 
                                          :style="permission === 'view' ? 'cursor: no-drop; width: 180px; text-align: center;' : 'width: 180px; text-align: center;'"
                                          :class="permission === 'view' ? 'cursor-initial' : 'cursor-pointer'"
                                          @click="isDevSite ? logIdComingSoon() : logIdSite()"
                                          :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                                        >
                                          <span style="font-size: 15px; color: #656B85; text-align: center;">
                                            Claim
                                          </span>
                                        </b-dropdown-item>
                                      </b-dropdown>
                                    </div>
                                </div>
                                <b-button
                                  v-else
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;' : 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                                  size="sm"
                                  class=""
                                  @click="isDevSite ? logIdComingSoon() : logIdSite()"
                                  :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                                >
                                Claim
                                </b-button>
                                <span style="color: #FC7D7D; font-size: 13px;" class="d-block" v-if="siteObj[0].ownedBy.lost">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                              </div>
                              <div v-else>
                                <div class="d-flex">
                                  <b-avatar
                                    size="27"
                                    :src="siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.foto : ''"
                                    variant="light-dark"
                                    class="badge-minimal"
                                    alt="img user"
                                    style="background: #ddd !important;"
                                    :text="avatarText(!siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.name : '')"
                                  >
                                    <feather-icon
                                      v-if="!siteObj[0].ownedBy.foto && !siteObj[0].ownedBy.name"
                                      icon="UserIcon"
                                      size="22"
                                    />
                                  </b-avatar>

                                  <div :class="userData.uid === siteObj[0].ownedBy.uid ? 'ml-25 mt-25' : 'ml-25 mt-50'">
                                    <p
                                      v-if="userData.uid === siteObj[0].ownedBy.uid"
                                      class="mb-0 "
                                      style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                                    >
                                      Me
                                    </p>
                                    <p
                                      v-else
                                      class="mb-0 "
                                      style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                                    >
                                      {{ siteObj[0].ownedBy.name.substring(0, 15) + (siteObj[0].ownedBy.name.length > 15 ? '...' : '') }}
                                    </p>
                                  </div>
                                </div>
                                <span
                                  v-if="siteObj[0].ownedBy.lost"
                                  style="color: #FC7D7D; font-size: 13px;"
                                  class=""
                                >You lost access <feather-icon
                                  icon="HelpCircleIcon"
                                  style="color: #FC7D7D;"
                                /></span>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
              <b-row style="width: 220px; padding-right: 5px;" class="row-no-container-domain-name">
                <b-col
                  md="12"
                  align-self="baseline"
                  class="margin-right: 5px;"
                >
                  <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cursor-pointer cards-no-domain-site-name" @click="getError">
                    <b-card-text>
                      <template>
                        <div
                          class="text-nowrap"
                          style="display: block;"
                        >
                          <template>
                            <b-button
                              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                              variant="flat-warning"
                              style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                              class="btn-icon rounded-circle"
                            >
                              <b-img-lazy
                                :src="imgCurrent"
                                alt="logo"
                              />
                              <!-- <b-icon icon="building" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                            </b-button>
                            <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                              <span
                                style="color: #A7AAB9; font-size: 16px;"
                                class=""
                              >Current Errors</span>
                              <!-- <span style="width: 100%; font-size: 15px; color: #656B85;" class="">asdf</span> -->
                              <span
                                class=""
                                style="font-size: 18px; color: #FC7D7D; width: 100%;"
                              >
                                {{ totalErrors }}
                                <b-avatar
                                  variant="light-danger"
                                  size="12px"
                                  style="margin-bottom: 7px; background: #ddd !important;"
                                >
                                  <feather-icon icon="ChevronDownIcon" />
                                </b-avatar>
                              </span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col
                  md="12"
                  align-self="baseline"
                >
                  <b-card style="height: 74px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cursor-pointer cards-no-domain-site-name" @click="goToStatus(siteObj[0].status)">
                    <b-card-text>
                      <template>
                        <div
                          class="text-nowrap"
                          style="display: block;"
                        >
                          <template>
                            <b-button
                              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                              variant="flat-warning"
                              style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                              class="btn-icon rounded-circle"
                            >
                              <b-img-lazy
                                :src="imgMembership"
                                alt="logo"
                              />
                              <!-- <b-icon icon="back" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                            </b-button>
                            <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                              <span
                                style="color: #A7AAB9; font-size: 16px;"
                                class=""
                              >Status</span>
                              <span
                                style="font-weight: 700; width: 100%; font-size: 15px; color: #656B85; text-transform: capitalize !important;"
                                class=""
                              >{{ siteObj[0].status }}</span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
              <b-row style="width: 220px; padding-right: 5px;" class="row-no-container-domain-name">
                <b-col
                  md="12"
                  align-self="baseline"
                  class="margin-right: 5px;"
                >
                  <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cursor-pointer" @click="getError">
                    <b-card-text>
                      <template>
                        <div
                          class="text-nowrap"
                          style="display: block;"
                        >
                          <template>
                            <b-button
                              v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                              variant="flat-warning"
                              style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                              class="btn-icon rounded-circle"
                            >
                              <b-img-lazy
                                :src="imgFixed"
                                alt="logo"
                              />
                              <!-- <b-icon icon="house-fill" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                            </b-button>
                            <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                              <span
                                style="color: #A7AAB9; font-size: 16px;"
                                class=""
                              >Fixed Issues</span>
                              <!-- <span style="width: 100%; font-size: 15px; color: #656B85;" class="">asdf</span> -->
                              <span
                                class=""
                                style="font-size: 18px; color: #53D991; width: 100%;"
                              >
                                {{ siteObj[0].fixedIssues }}
                                <b-avatar
                                  variant="light-success"
                                  size="12px"
                                  style="margin-bottom: 7px; background: #ddd !important;"
                                >
                                  <feather-icon icon="ChevronUpIcon" />
                                </b-avatar>
                              </span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card>
                </b-col>
                <b-col
                  md="12"
                  align-self="baseline"
                  class="row-no-container-domain-name-md"
                >
                  <b-dropdown
                    :text="siteObj[0]['membership']"
                    :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                    variant="outline-secondary"
                    :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                    v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                  >
                      <template #button-content>
                          <b-img-lazy
                            fluid
                            :src="proIcon"
                            alt="pro page img"
                            width="17"
                            height="16"
                            style="margin-bottom: 7px;"
                            class="mr-0"
                            loading="lazy"
                            v-if="siteObj[0]['membership'] !== 'Free'"
                          />
                          <!-- {{siteObj[0]['membership']}} -->
                          {{siteObj[0]['membership'] === 'Free license' ? 'Free' : 'Licensed'}}
                          <!-- Licensed -->
                      </template>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                              Upgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                              Downgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    :text="siteObj[0]['membership']"
                    :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                    variant="outline-secondary"
                    :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                    v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                  >
                      <template #button-content>
                          <b-img-lazy
                            fluid
                            :src="proIcon"
                            alt="pro page img"
                            width="17"
                            height="16"
                            style="margin-bottom: 7px;"
                            class="mr-0"
                            loading="lazy"
                            v-if="siteObj[0]['membership'] !== 'Free'"
                          />
                          <!-- {{siteObj[0]['membership']}} -->
                          {{siteObj[0]['membership'] === 'Free license' ? 'Free' : 'Licensed'}}
                          <!-- Free -->
                      </template>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                              Upgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                              Downgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                  </b-dropdown>
                  <b-button
                    variant="outline-secondary"
                    block
                    class="ml-3 px-0 py-25 mb-2"
                    :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                    pill
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    pill
                    class="ml-1 mb-2"
                    style="font-weight: 700; border-radius: 21px;"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] === 'Free' && isDevSite"
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade your license
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    block
                    class="ml-3 px-0 py-25 mb-2"
                    :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                    pill
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    pill
                    class="ml-1 mb-2"
                    style="font-weight: 700; border-radius: 21px;"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] === 'Free' && !isDevSite"
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade your license
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="width: 220px; padding-right: 5px;" class="row-no-container-domain-name-sm">
                <b-col
                  md="12"
                  align-self="baseline"
                >
                  <b-dropdown
                    :text="siteObj[0]['membership']"
                    :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                    variant="outline-secondary"
                    :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                    v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                  >
                      <template #button-content>
                          <b-img-lazy
                            fluid
                            :src="proIcon"
                            alt="pro page img"
                            width="17"
                            height="16"
                            style="margin-bottom: 7px;"
                            class="mr-0"
                            loading="lazy"
                            v-if="siteObj[0]['membership'] !== 'Free'"
                          />
                          Licensed
                      </template>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                              Upgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                              Downgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    :text="siteObj[0]['membership']"
                    :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                    variant="outline-secondary"
                    :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                    v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                  >
                      <template #button-content>
                          <b-img-lazy
                            fluid
                            :src="proIcon"
                            alt="pro page img"
                            width="17"
                            height="16"
                            style="margin-bottom: 7px;"
                            class="mr-0"
                            loading="lazy"
                            v-if="siteObj[0]['membership'] !== 'Free'"
                          />
                          <!-- {{siteObj[0]['membership']}} -->
                          {{siteObj[0]['membership'] === 'Free license' ? 'Free' : 'Licensed'}}
                          <!-- Licensed -->
                      </template>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                              Upgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                      <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                          <span style="font-size: 15px; color: #656B85;">
                              <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                              Downgrade
                          </span><br>
                          <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                      </b-dropdown-item>
                  </b-dropdown>
                  <b-button
                    variant="outline-secondary"
                    block
                    class="ml-3 px-0 py-25 mb-2"
                    :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                    pill
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    pill
                    class="ml-1 mb-2"
                    style="font-weight: 700; border-radius: 21px;"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] === 'Free' && isDevSite"
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade your license
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    block
                    class="ml-3 px-0 py-25 mb-2"
                    :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                    pill
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    pill
                    class="ml-1 mb-2"
                    style="font-weight: 700; border-radius: 21px;"
                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    v-if="siteObj[0]['membership'] === 'Free' && !isDevSite"
                    @click="showOpenInfoLicense = true"
                  >
                    Upgrade your license
                  </b-button>
                </b-col>
              </b-row>
            </b-row>

            <b-row class="pl-0 ml-0 row-md-container-edit-site">
              <b-col md="2" sm="5" class="pr-0 pl-0 img">
                <b-row style="width: 220px; padding-right: 5px;" class="row-container-img">
                  <b-col
                    md="12"
                    align-self="baseline"
                    style="margin-right: 5px;"
                  >
                    <b-nav-item-dropdown
                      id="dropdown-edit-site-id"
                      ref="dropdown"
                      right
                      toggle-class="d-flex align-items-center dropdown-user-link nav-link-custom"
                      class="dropdown-edit-site pb-75"
                    >
                      <template #button-content>
                        <b-img-lazy
                          :src="siteObj[0].imgSite"
                          :alt="'img not found'"
                          style="width: 175px; height: 168px; object-fit: contain; margin-top: 0px !important; padding-top: 0px; position: relative; top: -11px;"
                          class="mt-1"
                          onerror="this.onerror=null;this.src='https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b';"
                        />
                      </template>
                      <ul class="dropdown-menu dropdown-menu-right" style="top: -120px !important; left: -63px !important;">
                        <b-dropdown-item
                          :href="siteObj[0].domain"
                          link-class="d-flex align-items-center item-dropdown-user"
                          style="font-size: 13px;"
                          target="_blank"
                        >
                          <span>Open in new tab</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          @click.prevent="getError"
                          link-class="d-flex align-items-center item-dropdown-user"
                          style="font-size: 13px;"
                        >
                          <span>Fix errors</span>
                        </b-dropdown-item>
                      </ul>
                    </b-nav-item-dropdown>
                    <!-- </b-link> -->
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="10" sm="7" class="pr-0 pl-0 desc">
                <b-row style="width: 390px; padding-right: 5px;" class="row-container-domain-name">
                  <b-col
                    md="12"
                    sm="12"
                    align-self="baseline"
                    style="margin-right: 5px;"
                  >
                    <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="card-domain-site-name">
                      <b-card-text>
                        <template>
                          <div
                            class="text-nowrap"
                            style="display: block;"
                          >
                            <template>
                              <b-button
                                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                variant="flat-warning"
                                style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                class="btn-icon rounded-circle"
                              >
                                <b-img-lazy
                                  :src="imgSite"
                                  alt="logo"
                                />
                                <!-- <b-icon icon="building" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                class="btn-icon rounded-circle float-right ml-25 eyeBtn"
                                size="sm"
                                style="background: #F9F9F9 !important; border: 1px solid #F9F9F9 !important; width: 24px; height: 24px; padding: 0.1rem;"
                                target="_blank"
                                :href="siteObj[0].domain"
                              >
                                <feather-icon
                                  icon="ExternalLinkIcon"
                                  class="p-0 ml-0 eyeBtn"
                                />
                              </b-button>
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                class="btn-icon rounded-circle float-right"
                                size="sm"
                                style="background: #F9F9F9 !important; border: 1px solid #F9F9F9 !important; width: 24px; height: 24px; padding: 0.1rem; z-index: 11; position: relative;"
                                @click.prevent="logIdSiteName()"
                              >
                                <feather-icon
                                  icon="Edit2Icon"
                                  style="color: #A7AAB9; fill: #A7AAB9;"
                                  class="p-0 ml-0"
                                />
                              </b-button>
                              <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                                <span
                                  style="color: #A7AAB9; font-size: 16px;"
                                  class=""
                                >Site Name</span>
                                <span
                                  style="font-weight: 700; width: 100%; font-size: 15px; color: #656B85; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden; display: block;"
                                  class=""
                                >{{ siteObj[0].name }}</span>
                              </div>
                            </template>
                          </div>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col
                    md="12"
                    align-self="baseline"
                    class="margin-right: 5px;"
                  >
                    <b-link
                      style="font-size: 16px; text-decoration: none; color: #7838ff;"
                      :href="siteObj[0].domain"
                      target="_blank"
                    >
                      <b-card style="height: 74px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="card-domain-site-name">
                        <b-card-text>
                          <template>
                            <div
                              class="text-nowrap"
                              style="display: block;"
                            >
                              <template>
                                <b-button
                                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                  variant="flat-warning"
                                  style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                  class="btn-icon rounded-circle"
                                >
                                  <b-img-lazy
                                    :src="imgDomain"
                                    alt="logo"
                                  />
                                  <!-- <b-icon icon="building" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                                </b-button>
                                <b-button
                                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                  variant="outline-primary"
                                  class="btn-icon rounded-circle float-right ml-25 eyeBtn"
                                  size="sm"
                                  style="background: #F9F9F9 !important; border: 1px solid #F9F9F9 !important; width: 24px; height: 24px; padding: 0.1rem;"
                                  target="_blank"
                                  :href="siteObj[0].domain"
                                >
                                  <feather-icon
                                    icon="ExternalLinkIcon"
                                    class="p-0 ml-0 eyeBtn"
                                  />
                                </b-button>
                                <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                                  <span
                                    style="color: #A7AAB9; font-size: 16px;"
                                    class=""
                                  >Domain</span>
                                  <span
                                    style="width: 100%; font-size: 15px; color: #656B85; text-overflow: ellipsis !important; white-space: nowrap; overflow: hidden; display: block;"
                                    class=""
                                  >
                                    <b-link
                                      style="font-size: 16px; text-decoration: none; color: #656B85 !important;"
                                      :href="siteObj[0].domain"
                                      target="_blank"
                                    >
                                      <feather-icon
                                        icon="ExternalLinkIcon"
                                        class="p-0 ml-0 mr-0"
                                        style="margin-bottom: 7px !important; margin-left: 0px !important; color: #656B85;"
                                      />
                                      {{ siteObj[0].domainFix }}
                                    </b-link>
                                  </span>
                                </div>
                              </template>
                            </div>
                          </template>
                        </b-card-text>
                      </b-card>
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="pl-0 ml-0 row-md-container-edit-site">
              <b-col md="4" sm="12" class="pr-0 pl-0 col-md-container-edit-site">
                <b-row style="width: 230px; padding-right: 5px;" class="row-no-container-domain-name">
                  <b-col
                    md="12"
                    align-self="baseline"
                  >
                    <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cards-no-domain-site-name">
                      <b-card-text>
                        <template>
                          <div
                            class="text-nowrap"
                            style="display: block;"
                          >
                            <template>
                              <b-button
                                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                variant="flat-warning"
                                style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                class="btn-icon rounded-circle"
                              >
                                <b-img-lazy
                                  :src="imgKey"
                                  alt="logo"
                                />
                                <!-- <b-icon icon="back" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                              </b-button>
                              <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                                <span
                                  style="color: #A7AAB9; font-size: 16px;"
                                  class=""
                                >Key</span>
                                <!-- <span style="width: 100%; font-size: 15px; color: #656B85;" class="">asdf</span> -->
                                <div style="display: flex;">
                                  <span
                                    class=""
                                    v-if="!keyVisible"
                                    style="font-size: 14px; color: #656B85;"
                                  >••••••••{{ siteObj[0].key.length === 21 ? siteObj[0].key.slice(19) : siteObj[0].key.slice(20) }}</span>
                                  <span
                                    v-else
                                    class=""
                                    style="font-size: 14px; color: #656B85;"
                                  >{{ keyID }}</span>
                                  <b-button
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-dark"
                                    style="color: #A7AAB9; width: 24px; height: 24px; padding: 0.1rem;"
                                    class="btn-icon rounded-circle ml-1"
                                    @click.prevent="toggleVisibleKey"
                                  >
                                    <feather-icon icon="EyeIcon" v-if="!keyVisible" />
                                    <feather-icon icon="EyeOffIcon" v-if="keyVisible" />
                                  </b-button>
                                  <b-button
                                    v-clipboard:copy="keyID"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                    variant="flat-dark"
                                    style="color: #A7AAB9; width: 24px; height: 24px; padding: 0.1rem;"
                                    class="btn-icon rounded-circle float-right"
                                  >
                                    <feather-icon icon="CopyIcon" />
                                  </b-button>
                                </div>
                              </div>
                            </template>
                          </div>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col
                    md="12"
                    align-self="baseline"
                  >
                    <b-card no-body style="height: 74px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cards-no-domain-site-name">
                      <b-card-text style="margin: auto 25px;">
                        <template>
                          <div
                            class=""
                            style=""
                          >
                            <template>
                              <b-button
                                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                variant="flat-warning"
                                style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                class="btn-icon rounded-circle"
                              >
                                <b-img-lazy
                                  :src="imgOwned"
                                  alt="logo"
                                />
                                <!-- <b-icon icon="back" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                              </b-button>
                              <div style="width: 50%; display: inline-block; margin-left: 10px; vertical-align: middle;">
                                <span
                                  style="color: #A7AAB9; font-size: 14px;"
                                  class=""
                                >Owned by</span>
                                <!-- <span style="font-weight: 700; width: 100%; font-size: 15px; color: #656B85;" class="">{{ siteObj[0].ownedBy }}</span> -->
                                <div v-if="userData.uid !== siteObj[0].ownedBy.uid || !siteObj[0].ownedBy || !siteObj[0].ownedBy.uid">
                                  <div class="d-flex mb-50" v-if="(permission === 'owner' || permission === 'admin' || permission === 'edit' || permission === 'view') && siteObj[0].ownedBy.uid">
                                      <b-avatar
                                        size="27"
                                        :src="siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.foto : ''"
                                        variant="light-dark"
                                        class="badge-minimal"
                                        alt="img user"
                                        :text="avatarText(!siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.name : '')"
                                        style="background: #ddd !important;"
                                        v-if="userData.uid === siteObj[0].ownedBy.uid"
                                      >
                                          <feather-icon
                                            v-if="!siteObj[0].ownedBy.foto && !siteObj[0].ownedBy.name"
                                            icon="UserIcon"
                                            size="22"
                                          />
                                      </b-avatar>

                                      <div class="ml-25 mt-50">
                                        <p class="mb-0" style="font-size: 13px; line-height: 14px; color: #656B85; cursor: initial !important;" v-if="userData.uid === siteObj[0].ownedBy.uid">
                                          {{ siteObj[0].ownedBy.name.substring(0, 15) + (siteObj[0].ownedBy.name.length > 15 ? '...' : '') }}
                                        </p>

                                        <b-dropdown
                                          :text="siteObj[0].membership"
                                          variant="outline-secondary"
                                          class="style-chooser cursor-pointer"
                                          :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' || permission === 'view' ? false : true"
                                          v-else
                                        >
                                          <template #button-content>
                                            <b-avatar
                                              size="27"
                                              :src="siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.foto : ''"
                                              variant="light-dark"
                                              class="badge-minimal"
                                              alt="img user"
                                              :text="avatarText(!siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.name : '')"
                                              style="background: #ddd !important;"
                                            >
                                              <feather-icon
                                                v-if="!siteObj[0].ownedBy.foto && !siteObj[0].ownedBy.name"
                                                icon="UserIcon"
                                                size="22"
                                              />
                                            </b-avatar>
                                            <span class="ml-25 mt-50 mb-0" style="font-size: 13px; line-height: 14px; color: #656B85;">
                                              {{ siteObj[0].ownedBy.name.substring(0, 15) + (siteObj[0].ownedBy.name.length > 15 ? '...' : '') }}
                                            </span>
                                          </template>
                                          <b-dropdown-item 
                                            :style="permission === 'view' ? 'cursor: no-drop; width: 180px; text-align: center;' : 'width: 180px; text-align: center;'"
                                            :class="permission === 'view' ? 'cursor-initial' : 'cursor-pointer'"
                                            @click="isDevSite ? logIdComingSoon() : logIdSite()"
                                            :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                                          >
                                            <span style="font-size: 15px; color: #656B85; text-align: center;">
                                              Claim
                                            </span>
                                          </b-dropdown-item>
                                        </b-dropdown>
                                      </div>
                                  </div>
                                  <b-button
                                    v-else
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;' : 'background: #DCDCF252 !important; border: 1px solid #DCDCF2 !important; border-radius: 10px; color: #656B85 !important; font-size: 16px;'"
                                    size="sm"
                                    class=""
                                    @click="isDevSite ? logIdComingSoon() : logIdSite()"
                                    :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                                  >
                                  Claim
                                  </b-button>
                                  <span style="color: #FC7D7D; font-size: 13px;" class="d-block" v-if="siteObj[0].ownedBy.lost">You lost access <feather-icon icon="HelpCircleIcon" style="color: #FC7D7D;" /></span>
                                </div>
                                <div v-else>
                                  <div class="d-flex">
                                    <b-avatar
                                      size="27"
                                      :src="siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.foto : ''"
                                      variant="light-dark"
                                      class="badge-minimal"
                                      alt="img user"
                                      style="background: #ddd !important;"
                                      :text="avatarText(!siteObj[0].ownedBy.foto ? siteObj[0].ownedBy.name : '')"
                                    >
                                      <feather-icon
                                        v-if="!siteObj[0].ownedBy.foto && !siteObj[0].ownedBy.name"
                                        icon="UserIcon"
                                        size="22"
                                      />
                                    </b-avatar>

                                    <div :class="userData.uid === siteObj[0].ownedBy.uid ? 'ml-25 mt-25' : 'ml-25 mt-50'">
                                      <p
                                        v-if="userData.uid === siteObj[0].ownedBy.uid"
                                        class="mb-0 "
                                        style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                                      >
                                        Me
                                      </p>
                                      <p
                                        v-else
                                        class="mb-0 "
                                        style="font-size: 13px; line-height: 14px; color: #656B85; text-overflow: ellipsis; overflow: hidden; width: 8rem; display: inline-block;"
                                      >
                                        {{ siteObj[0].ownedBy.name.substring(0, 15) + (siteObj[0].ownedBy.name.length > 15 ? '...' : '') }}
                                      </p>
                                    </div>
                                  </div>
                                  <span
                                    v-if="siteObj[0].ownedBy.lost"
                                    style="color: #FC7D7D; font-size: 13px;"
                                    class=""
                                  >You lost access <feather-icon
                                    icon="HelpCircleIcon"
                                    style="color: #FC7D7D;"
                                  /></span>
                                </div>
                              </div>
                            </template>
                          </div>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
              
              <b-col md="4" sm="12" class="pr-0 pl-0 col-md-container-edit-site">
                <b-row style="width: 220px; padding-right: 5px;" class="row-no-container-domain-name">
                  <b-col
                    md="12"
                    align-self="baseline"
                    class="margin-right: 5px;"
                  >
                    <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cursor-pointer cards-no-domain-site-name" @click="getError">
                      <b-card-text>
                        <template>
                          <div
                            class="text-nowrap"
                            style="display: block;"
                          >
                            <template>
                              <b-button
                                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                variant="flat-warning"
                                style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                class="btn-icon rounded-circle"
                              >
                                <b-img-lazy
                                  :src="imgCurrent"
                                  alt="logo"
                                />
                                <!-- <b-icon icon="building" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                              </b-button>
                              <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                                <span
                                  style="color: #A7AAB9; font-size: 16px;"
                                  class=""
                                >Current Errors</span>
                                <!-- <span style="width: 100%; font-size: 15px; color: #656B85;" class="">asdf</span> -->
                                <span
                                  class=""
                                  style="font-size: 18px; color: #FC7D7D; width: 100%;"
                                >
                                  {{ totalErrors }}
                                  <b-avatar
                                    variant="light-danger"
                                    size="12px"
                                    style="margin-bottom: 7px; background: #ddd !important;"
                                  >
                                    <feather-icon icon="ChevronDownIcon" />
                                  </b-avatar>
                                </span>
                              </div>
                            </template>
                          </div>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col
                    md="12"
                    align-self="baseline"
                  >
                    <b-card style="height: 74px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cursor-pointer cards-no-domain-site-name" @click="goToStatus(siteObj[0].status)">
                      <b-card-text>
                        <template>
                          <div
                            class="text-nowrap"
                            style="display: block;"
                          >
                            <template>
                              <b-button
                                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                variant="flat-warning"
                                style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                class="btn-icon rounded-circle"
                              >
                                <b-img-lazy
                                  :src="imgMembership"
                                  alt="logo"
                                />
                                <!-- <b-icon icon="back" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                              </b-button>
                              <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                                <span
                                  style="color: #A7AAB9; font-size: 16px;"
                                  class=""
                                >Status</span>
                                <span
                                  style="font-weight: 700; width: 100%; font-size: 15px; color: #656B85; text-transform: capitalize !important;"
                                  class=""
                                >{{ siteObj[0].status }}</span>
                              </div>
                            </template>
                          </div>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="4" sm="12" class="pr-0 pl-0 col-md-container-edit-site">
                <b-row style="width: 220px; padding-right: 5px;" class="row-no-container-domain-name">
                  <b-col
                    md="12"
                    align-self="baseline"
                    class="margin-right: 5px;"
                  >
                    <b-card style="height: 74px; margin-bottom: 10px; margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;" class="cursor-pointer" @click="getError">
                      <b-card-text>
                        <template>
                          <div
                            class="text-nowrap"
                            style="display: block;"
                          >
                            <template>
                              <b-button
                                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                                variant="flat-warning"
                                style="background: #F0F1F7 !important; padding: 5px; width: 40px; height: 40px; cursor: default;"
                                class="btn-icon rounded-circle"
                              >
                                <b-img-lazy
                                  :src="imgFixed"
                                  alt="logo"
                                />
                                <!-- <b-icon icon="house-fill" style="color: #ff5e00; width: 24px; height: 24px;"></b-icon> -->
                              </b-button>
                              <div style="width: 50%; display: inline-grid; position: absolute; margin-left: 10px;">
                                <span
                                  style="color: #A7AAB9; font-size: 16px;"
                                  class=""
                                >Fixed Issues</span>
                                <!-- <span style="width: 100%; font-size: 15px; color: #656B85;" class="">asdf</span> -->
                                <span
                                  class=""
                                  style="font-size: 18px; color: #53D991; width: 100%;"
                                >
                                  {{ siteObj[0].fixedIssues }}
                                  <b-avatar
                                    variant="light-success"
                                    size="12px"
                                    style="margin-bottom: 7px; background: #ddd !important;"
                                  >
                                    <feather-icon icon="ChevronUpIcon" />
                                  </b-avatar>
                                </span>
                              </div>
                            </template>
                          </div>
                        </template>
                      </b-card-text>
                    </b-card>
                  </b-col>
                  <b-col
                    md="12"
                    align-self="baseline"
                    class="row-no-container-domain-name-md"
                  >
                    <b-dropdown
                      :text="siteObj[0]['membership']"
                      :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                      variant="outline-secondary"
                      :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                      v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    >
                        <template #button-content>
                            <b-img-lazy
                              fluid
                              :src="proIcon"
                              alt="pro page img"
                              width="17"
                              height="16"
                              style="margin-bottom: 7px;"
                              class="mr-0"
                              loading="lazy"
                              v-if="siteObj[0]['membership'] !== 'Free'"
                            />
                            <!-- {{siteObj[0]['membership']}} -->
                            {{siteObj[0]['membership'] === 'Free license' ? 'Free' : 'Licensed'}}
                            <!-- Licensed -->
                        </template>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                Upgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                Downgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                      :text="siteObj[0]['membership']"
                      :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                      variant="outline-secondary"
                      :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                      v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    >
                        <template #button-content>
                            <b-img-lazy
                              fluid
                              :src="proIcon"
                              alt="pro page img"
                              width="17"
                              height="16"
                              style="margin-bottom: 7px;"
                              class="mr-0"
                              loading="lazy"
                              v-if="siteObj[0]['membership'] !== 'Free'"
                            />
                            <!-- {{siteObj[0]['membership']}} -->
                            {{siteObj[0]['membership'] === 'Free license' ? 'Free' : 'Licensed'}}
                            <!-- Free -->
                        </template>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                Upgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                Downgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                      variant="outline-secondary"
                      block
                      class="ml-3 px-0 py-25 mb-2"
                      :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                      pill
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      pill
                      class="ml-1 mb-2"
                      style="font-weight: 700; border-radius: 21px;"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] === 'Free' && isDevSite"
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade your license
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      block
                      class="ml-3 px-0 py-25 mb-2"
                      :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                      pill
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      pill
                      class="ml-1 mb-2"
                      style="font-weight: 700; border-radius: 21px;"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] === 'Free' && !isDevSite"
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade your license
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="4" sm="12" class="pr-0 pl-0 col-md-container-edit-site">
                <b-row style="width: 220px; padding-right: 5px;" class="row-no-container-domain-name-sm">
                  <b-col
                    md="12"
                    align-self="baseline"
                  >
                    <b-dropdown
                      :text="siteObj[0]['membership']"
                      :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                      variant="outline-secondary"
                      :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                      v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    >
                        <template #button-content>
                            <b-img-lazy
                              fluid
                              :src="proIcon"
                              alt="pro page img"
                              width="17"
                              height="16"
                              style="margin-bottom: 7px;"
                              class="mr-0"
                              loading="lazy"
                              v-if="siteObj[0]['membership'] !== 'Free'"
                            />
                            <!-- {{siteObj[0]['membership']}} -->
                            {{siteObj[0]['membership'] === 'Free license' ? 'Free' : 'Licensed'}}
                            <!-- Licensed -->
                        </template>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                Upgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                Downgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown
                      :text="siteObj[0]['membership']"
                      :style="permission === 'view' ? 'cursor: initial !important;' : ''"
                      variant="outline-secondary"
                      :class="siteObj[0]['membership'] === 'Free' ? 'style-chooser ml-3 mb-25' : 'style-chooser ml-2 mb-25'"
                      v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                    >
                        <template #button-content>
                            <b-img-lazy
                              fluid
                              :src="proIcon"
                              alt="pro page img"
                              width="17"
                              height="16"
                              style="margin-bottom: 7px;"
                              class="mr-0"
                              loading="lazy"
                              v-if="siteObj[0]['membership'] !== 'Free'"
                            />
                            <!-- {{siteObj[0]['membership']}} -->
                            {{siteObj[0]['membership'] === 'Free license' ? 'Free' : 'Licensed'}}
                            <!-- Licensed -->
                        </template>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowUpIcon" class="mt-0 pt-0" size="12" />
                                Upgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                        <b-dropdown-item style="width: 180px;" @click="showOpenInfoLicense = true">
                            <span style="font-size: 15px; color: #656B85;">
                                <feather-icon icon="ArrowDownIcon" class="mt-0 pt-0" size="12" />
                                Downgrade
                            </span><br>
                            <span style="padding-left: 1.3rem; white-space: break-spaces; color: #A7AAB9; font-size: 12px; display: block; line-height: 15px;">You need to claim the site to upgrade license</span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-button
                      variant="outline-secondary"
                      block
                      class="ml-3 px-0 py-25 mb-2"
                      :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] !== 'Free' && isDevSite"
                      pill
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      pill
                      class="ml-1 mb-2"
                      style="font-weight: 700; border-radius: 21px;"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] === 'Free' && isDevSite"
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade your license
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      block
                      class="ml-3 px-0 py-25 mb-2"
                      :style="permission === 'view' ? 'pointer-events: initial !important; cursor: initial !important; color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;' : 'color: #7838FF !important; font-size: 13px !important; max-width: 76px; margin: auto;'"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] !== 'Free' && !isDevSite"
                      pill
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      pill
                      class="ml-1 mb-2"
                      style="font-weight: 700; border-radius: 21px;"
                      :disabled="permission === 'owner' || permission === 'admin' || permission === 'edit' ? false : true"
                      v-if="siteObj[0]['membership'] === 'Free' && !isDevSite"
                      @click="showOpenInfoLicense = true"
                    >
                      Upgrade your license
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row class="mb-75 mt-1 crawl-info-edit-site-md">
              <b-col md="4" >
                <b-card style="margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;">
                  <b-card-text
                    class="mb-25"
                    style="font-weight: 700; color: #181E38; font-size: 18px;"
                  >
                    Your site has been Crawled
                  </b-card-text>
                  
                  <div
                    :class="(crawls && crawls.estatus === 'running') || crawlRunning ? 'pr-0 mr-0' : 'pl-0 ml-0 mr-0'"
                  >
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-if="(crawls && crawls.estatus === 'running') || crawlRunning"
                    >
                      {{
                        `Crawling: ${ processRequests ? processRequests : 1 } out of ${ crawls.availableURL && crawls.availableURL >= processRequests ? crawls.availableURL : processRequests ? processRequests : 4 }` 
                      }}
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else-if="crawls && (crawls.estatus === 'terminated' || crawls.estatus === 'incomplete')"
                    >
                      {{
                        crawls.estatus === 'terminated' ? `Crawl date: ${crawls.date}` : `Crawl date: ${crawls.date} | Pages: ${ crawls.requests ? crawls.requests : 1 } of ${crawls.availableURL && crawls.availableURL >= crawls.requests ? crawls.availableURL : crawls.requests ? crawls.requests : 4}`
                      }}
                      <b-button
                        style="background: transparent !important; border: 0px; display: inline !important; position: absolute; padding-top: 2px !important;"
                        class="pt-0 pr-0 pb-0 ml-0 eyeBtns linkURL pl-25"
                        @click.prevent="showOpenIncompleteCrawl = true"
                      >
                        <feather-icon
                          icon="AlertCircleIcon"
                          class="cursor-pointer pl-0 ml-0"
                          style="color: #7838ff !important;"
                          size="15"
                        />
                      </b-button>
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else-if="crawls && crawls.estatus === 'endSuccess'"
                    >
                      {{
                        `Crawl date: ${crawls.date}`
                      }}
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else-if="crawls && crawls.estatus === 'timeout'"
                    >
                      {{
                        `Crawl date: ${crawls.date}`
                      }}
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else
                    >
                      {{
                        crawls && (lastCrawl && crawls.id === lastCrawl) ? 
                          `Crawl date: ${crawls.date}` 
                        : crawls.estatus === 'running' ? 
                          `Crawling: ${lastCrawl && crawls.id !== lastCrawl ? lastCrawlTotalRequest : crawls.requests} out of ${!crawls ? lastCrawlTotalRequest : lastCrawl && crawls.id !== lastCrawl ? lastCrawlTotalRequest : crawls.availableURL ? crawls.availableURL : crawls.requests} pages`
                        : crawls && crawls.id ?
                          `Crawl date: ${crawls.date}` 
                        : lastCrawl && crawls.id !== lastCrawl ? 
                          `Crawling: 1 out of 4 pages` 
                        : `Crawl date: Not yet`
                      }}
                    </span>
                    <b-badge variant="light-info" class="ml-1" style="height: 20px; margin-top: 5px;" v-if="((crawls && crawls.estatus === 'running') || crawlRunning) && !incompleteCrawl">
                      Running
                    </b-badge>
                    <b-badge variant="light-success" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="incompleteCrawl || crawls.estatus === 'incomplete'">
                      Completed
                    </b-badge>
                    <b-badge variant="light-success" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="crawls.estatus === 'endSuccess'">
                      Completed
                    </b-badge>
                    <b-badge variant="light-warning" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="crawls.estatus === 'terminated'">
                      Terminated
                    </b-badge>
                    <b-badge variant="light-warning" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="crawls.estatus === 'timeout'">
                      Timeout
                    </b-badge>
                  </div>

                  <b-progress
                    :value="!crawls ? 0 : incompleteCrawl || crawls.estatus === 'incomplete' || crawls.estatus === 'terminated' || crawls.estatus === 'endSuccess' ? 1 : processRequests ? processRequests : 1"
                    :max="!crawls ? 4 : incompleteCrawl || crawls.estatus === 'incomplete' || crawls.estatus === 'terminated' || crawls.estatus === 'endSuccess' ? 1 : crawls.availableURL && crawls.availableURL >= processRequests ? crawls.availableURL : processRequests ? processRequests : 4"
                    class="progress-bar-info mt-1"
                    variant="info"
                    style="width: 90%;"
                    v-if="((crawls && crawls.estatus === 'running') || crawlRunning) || !refreshPage"
                  />
                  <b-progress
                    :value="0"
                    :max="1"
                    class="progress-bar-info mt-1"
                    variant="info"
                    style="width: 90%;"
                    v-else
                  />
                </b-card>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col xl="8" md="12" sm="12">
                <b-overlay
                  :show="loaderPages"
                  rounded="xl"
                  no-fade
                  class="tabs-errs"
                >
                  <div>
                    <h3
                      class="color-text-dark"
                      style="font-weight: 700;"
                    >
                      Select your SEOcloud copilot
                    </h3>
                    <span class="">Select your SEOcloud copilot and start fixing errors</span>
                  </div>
                  <!-- :select-mode="selectMode" -->
                  <!-- @row-selected="onRowSelected" -->
                  <div class="top-scroll-horizontal-tabs-errs" style="overflow-x: scroll;"><div class="div1" id="tab-err-sc2" style=" height: 20px;"></div></div>
                  <b-table
                    v-model="visibleRows"
                    :items="loadingNewItemsPagesOverview"
                    :fields="logicOwner === 'other' ? fieldsPagesOverviewDev : fieldsPagesOverview"
                    :filter="searchSite"
                    :filter-ignored-fields="['inlinks','inlinksList']"
                    foot-clone
                    responsive
                    ref="selectableTable"
                    :selectable="true"
                    style="display: block !important;"
                    id="tab-err-sc1"
                    style-class="vgt-table"
                    show-empty
                    sort-icon-right
                    class="vgt-table tableEditSite"
                    empty-filtered-text="Page not found"
                  >
                    <template #empty="">
                      <span></span>
                    </template>
                    <template #cell(selected)="data">
                      <div class="d-flex" v-if="data.item.status === 200 && !data.item.cUrl.includes('.xml')">
                        <b-button
                          style="background: transparent !important; border: 0px; display: inline !important; margin-right: 8px !important;"
                          target="_blank"
                          :href="data.item.cUrl"
                          class="p-0 ml-0 eyeBtns linkURL pl-25"
                        >
                          <feather-icon
                            icon="ExternalLinkIcon"
                            class="cursor-pointer eyeBtns"
                            style="color: #656b85 !important;"
                            size="18"
                          />
                        </b-button>
                        <!-- <b-form-checkbox
                          v-model="data.rowSelected"
                          :class="itemsPagesOverview.length === selected.length ? 'custom-control-gray' : 'custom-control-primary'"
                          :style="siteObj[0].permission === 'view' ? 'margin-left: 5px !important;' : 'margin-left: 5px !important;'"
                          :disabled=" false"
                          :value="true"
                          @change="observerCheckbox(data)"
                        >
                        </b-form-checkbox> -->
                      </div>
                      <div class="d-flex" v-else>
                        <b-button
                          style="background: transparent !important; border: 0px; display: inline !important; margin-right: 8px !important;"
                          target="_blank"
                          :href="data.item.cUrl"
                          class="p-0 ml-0 linkURL pl-25"
                        >
                          <feather-icon
                            icon="ExternalLinkIcon"
                            class="cursor-pointer"
                            style="color: #656b85 !important;"
                            size="18"
                          />
                        </b-button>
                      </div>
                    </template>
                    <template
                      #cell(page)="data"
                    >
                      {{ data.item.title ? data.item.title.substring(0, 60) + (data.item.title.length > 60 ? '...' : '') : data.item.page.substring(0, 60) + (data.item.page.length > 60 ? '...' : '') }}
                      <br>
                      <span style="font-size: 14px; color: #b9b9b9;" v-if="data.item.cUrl.includes('.xml')">
                        <b-badge :variant="data.item.cUrl.includes('.xml') ? 'light-info' : 'light-primary'" style="font-weight: 400;">
                          XML
                        </b-badge>
                        <b-badge class="ml-25" :variant="data.item.status === 404 ? 'light-danger' : 'light-primary'" style="font-weight: 400;" v-if="data.item.status !== 200">
                          {{data.item.status}}
                        </b-badge>
                        <feather-icon icon="ArrowRightIcon" class="mb-25 ml-25" v-if="data.item.redirectTo" />
                        {{data.item.redirectTo}}
                      </span>
                      <span style="font-size: 14px; color: #b9b9b9;" v-else-if="data.item.status === 200">{{ data.item.url.substring(0, 75) + (data.item.url.length > 75 ? '...' : '') }}</span>
                      <span style="font-size: 14px; color: #b9b9b9;" v-else-if="data.item.status">
                        <b-badge :variant="data.item.status === 404 ? 'light-danger' : 'light-primary'" style="font-weight: 400;">
                          {{data.item.status}}
                        </b-badge>
                        <feather-icon icon="ArrowRightIcon" class="mb-25 ml-25" v-if="data.item.redirectTo" />
                        {{data.item.redirectTo}}
                      </span>
                      <span style="font-size: 14px; color: #b9b9b9;" v-else-if="data.item.title === 'home' && data.item.url === 'Homepage'">
                        <span style="font-size: 14px; color: #b9b9b9;">{{ data.item.url.substring(0, 75) + (data.item.url.length > 75 ? '...' : '') }}</span>
                      </span>
                    </template>
                    <template
                      #cell(pro)="data"
                      @click.prevent.stop="showOpenSidebarProPages = true"
                    >
                      <b-button
                        style="background: transparent !important; border: 0px; display: inline !important; padding: 0px 10px !important;"
                        @click.prevent.stop="showOpenSidebarProPages = true"
                        class="ml-0"
                        v-if="(data.item.pro || data.item.status === 200) && !data.item.cUrl.includes('.xml')"
                      >
                        <b-img-lazy
                          v-if="data.item.pro"
                          fluid
                          :src="proIcon"
                          alt="pro page img"
                          width="18"
                          height="18"
                          class="mr-50"
                          loading="lazy"
                          @click.prevent.stop="showOpenSidebarProPages = true"
                        />
                        <b-img-lazy
                          v-else
                          fluid
                          :src="proIconNone"
                          alt="pro page img"
                          width="18"
                          height="18"
                          class="mr-50"
                          loading="lazy"
                          @click.prevent.stop="showOpenSidebarProPages = true"
                        />
                      </b-button>
                    </template>
                    <template
                      #cell(inlinks)="data"
                      @click.prevent.stop="showOpenInternalLinks = true, internalUrlExplore = data.item.cUrl, internalPages = data.item.inlinksList, internalTitleExplore = data.item.cTitle ? data.item.cTitle : ''"
                    >
                      <b-button
                        style="background: transparent !important; border: 0px; color: #656b85 !important; font-size: 17px !important; padding: 0px 10px !important;"
                        class="ml-0"
                        @click.prevent.stop="showOpenInternalLinks = true, internalUrlExplore = data.item.cUrl, internalPages = data.item.inlinksList, internalTitleExplore = data.item.cTitle ? data.item.cTitle : ''"
                      >
                        {{ data.item.inlinks ? data.item.inlinks : '-' }}
                      </b-button>
                    </template>
                    <template
                      #cell(errors)="data"
                    >
                      {{ (data.item.pro || data.item.status === 200) && !data.item.cUrl.includes('.xml')  ? (data.item.errors ? data.item.errors : '-') : '' }}
                    </template>

                    <template #foot(page)="">
                      <div style="text-align: right !important; margin: auto; width: 100%;" v-if="(crawls && crawls.estatus === 'running') || crawlRunning ? true : false">
                        <b-spinner
                          class=""
                          style="margin-right: 190px; color: #bbb !important;"
                        />
                      </div>
                      <span v-else></span>
                    </template>

                    <template #foot(pro)="">
                      <span></span>
                    </template>

                    <template #foot(inlinks)="">
                      <span></span>
                    </template>

                    <template #foot(errors)="">
                      <span></span>
                    </template>
                  </b-table>
                </b-overlay>
              </b-col>
              <b-col xl="4" md="4" class="crawl-info-edit-site-xl">
                <b-card style="margin-right: 5px; border: 1px solid #F0F1F7; border-radius: 10px;">
                  <b-card-text
                    class="mb-25"
                    style="font-weight: 700; color: #181E38; font-size: 18px;"
                  >
                    Your site has been Crawled
                  </b-card-text>
                  
                  <div
                    :class="(crawls && crawls.estatus === 'running') || crawlRunning ? 'pr-0 mr-0' : 'pl-0 ml-0 mr-0'"
                  >
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-if="(crawls && crawls.estatus === 'running') || crawlRunning"
                    >
                      {{
                        `Crawling: ${ processRequests ? processRequests : 1 } out of ${ crawls.availableURL && crawls.availableURL >= processRequests ? crawls.availableURL : processRequests ? processRequests : 4 }` 
                      }}
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else-if="crawls && (crawls.estatus === 'terminated' || crawls.estatus === 'incomplete')"
                    >
                      {{
                        crawls.estatus === 'terminated' ? `Crawl date: ${crawls.date}` : `Crawl date: ${crawls.date} | Pages: ${ crawls.requests ? crawls.requests : 1 } of ${crawls.availableURL && crawls.availableURL >= crawls.requests ? crawls.availableURL : crawls.requests ? crawls.requests : 4}`
                      }}
                      <b-button
                        style="background: transparent !important; border: 0px; display: inline !important; position: absolute; padding-top: 2px !important;"
                        class="pt-0 pr-0 pb-0 ml-0 eyeBtns linkURL pl-25"
                        @click.prevent="showOpenIncompleteCrawl = true"
                      >
                        <feather-icon
                          icon="AlertCircleIcon"
                          class="cursor-pointer pl-0 ml-0"
                          style="color: #7838ff !important;"
                          size="15"
                        />
                      </b-button>
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else-if="crawls && crawls.estatus === 'endSuccess'"
                    >
                      {{
                        `Crawl date: ${crawls.date}`
                      }}
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else-if="crawls && crawls.estatus === 'timeout'"
                    >
                      {{
                        `Crawl date: ${crawls.date}`
                      }}
                    </span>
                    <span
                      class=" d-block mt-75 ml-1"
                      style="margin-top: 0px;"
                      v-else
                    >
                      {{
                        crawls && (lastCrawl && crawls.id === lastCrawl) ? 
                          `Crawl date: ${crawls.date}` 
                        : crawls.estatus === 'running' ? 
                          `Crawling: ${lastCrawl && crawls.id !== lastCrawl ? lastCrawlTotalRequest : crawls.requests} out of ${!crawls ? lastCrawlTotalRequest : lastCrawl && crawls.id !== lastCrawl ? lastCrawlTotalRequest : crawls.availableURL ? crawls.availableURL : crawls.requests} pages`
                        : crawls && crawls.id ?
                          `Crawl date: ${crawls.date}` 
                        : lastCrawl && crawls.id !== lastCrawl ? 
                          `Crawling: 1 out of 4 pages` 
                        : `Crawl date: Not yet`
                      }}
                    </span>
                    <b-badge variant="light-info" class="ml-1" style="height: 20px; margin-top: 5px;" v-if="((crawls && crawls.estatus === 'running') || crawlRunning) && !incompleteCrawl">
                      Running
                    </b-badge>
                    <b-badge variant="light-success" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="incompleteCrawl || crawls.estatus === 'incomplete'">
                      Completed
                    </b-badge>
                    <b-badge variant="light-success" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="crawls.estatus === 'endSuccess'">
                      Completed
                    </b-badge>
                    <b-badge variant="light-warning" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="crawls.estatus === 'terminated'">
                      Terminated
                    </b-badge>
                    <b-badge variant="light-warning" class="ml-1" style="height: 20px; margin-top: 5px;" v-else-if="crawls.estatus === 'timeout'">
                      Timeout
                    </b-badge>
                  </div>

                  <b-progress
                    :value="!crawls ? 0 : incompleteCrawl || crawls.estatus === 'incomplete' || crawls.estatus === 'terminated' || crawls.estatus === 'endSuccess' ? 1 : processRequests ? processRequests : 1"
                    :max="!crawls ? 4 : incompleteCrawl || crawls.estatus === 'incomplete' || crawls.estatus === 'terminated' || crawls.estatus === 'endSuccess' ? 1 : crawls.availableURL && crawls.availableURL >= processRequests ? crawls.availableURL : processRequests ? processRequests : 4"
                    class="progress-bar-info mt-1"
                    variant="info"
                    style="width: 90%;"
                    v-if="((crawls && crawls.estatus === 'running') || crawlRunning) || !refreshPage"
                  />
                  <b-progress
                    :value="0"
                    :max="1"
                    class="progress-bar-info mt-1"
                    variant="info"
                    style="width: 90%;"
                    v-else
                  />
                </b-card>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BFormTextarea, BTab, BTabs, BImg, BListGroup, BListGroupItem,
  BAlert, BLink, BPopover, BFormInvalidFeedback, BFormFile, BSpinner, BFormCheckboxGroup, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BModal, BCardText, BCardBody, VBTooltip, BAvatarGroup,
  BMediaAside, BMediaBody, BImgLazy, BContainer, BIcon, BSidebar, VBToggle, BCollapse, BProgress, BDropdown, BDropdownItem, BBadge, BOverlay, BPagination, BFormSelect, BNavItemDropdown, BDropdownDivider,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { heightFade } from '@core/directives/animations'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ArchiveSite from '@/views/extensions/acl/table/sidebar/ArchiveSite.vue'
import ActivateSite from '@/views/extensions/acl/table/sidebar/ActivateSite.vue'
// import { codeMask } from './codeInput'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { mapActions } from 'vuex'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, email } from '@validations'
// import moment from 'moment'
import moment from 'moment-timezone'
import Cleave from 'vue-cleave-component'
import { SlickList, SlickItem } from 'vue-slicksort'
import VueEasyLightbox from 'vue-easy-lightbox'
import ProPages from '../sites-add/ProPages.vue'
import store from '@/store'
import router from '@/router'
import EditSiteName from './EditSiteName.vue'
import EditSiteDomain from './EditSiteDomain.vue'
import SidebarProPages from './SidebarProPages.vue'
import ComingSoon from '@/views/extensions/acl/table/sidebar/ComingSoon.vue'
import InfoLicense from '@/views/extensions/acl/table/sidebar/InfoLicense.vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import SidebarOwnership from '@/views/extensions/acl/table/sidebar/SidebarOwnership.vue'
import { db, auth, storage, dbErrors } from '@/firebase'

export default {
  name: 'EditSite',
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    'height-fade': heightFade,
    Ripple,
  },
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
    BFormInvalidFeedback,
    BFormFile,
    BPopover,
    BFormTextarea,
    BFormCheckboxGroup,
    BSpinner,
    SlickList,
    SlickItem,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BModal,
    BCardText,
    BCardBody,
    BImg,
    BAvatarGroup,
    BMediaAside,
    BMediaBody,
    BImgLazy,
    BContainer,
    BIcon,
    BCollapse,
    BProgress,
    BDropdown,
    BDropdownItem,
    BBadge,
    BOverlay,
    BPagination,
    BFormSelect,
    BNavItemDropdown,
    BDropdownDivider,

    // BList
    BListGroup,
    BListGroupItem,

    // Tab y Wizard
    FormWizard,
    TabContent,

    // Toast Notification
    ToastificationContent,

    // Collapse
    AppCollapse,
    AppCollapseItem,

    // Timeline
    AppTimeline,
    AppTimelineItem,

    // LightBox
    VueEasyLightbox,

    // table
    VueGoodTable,

    // sidebars
    BSidebar,
    ProPages,
    EditSiteName,
    EditSiteDomain,
    SidebarOwnership,
    ArchiveSite,
    ActivateSite,
    ComingSoon,
    InfoLicense,
    SidebarProPages,
  },
  mixins: [heightTransition],
  data() {
    return {
      // tables conf
      pageLength: 3,
      dir: false,
      isDevSite: false,

      userData: '',
      permission: '',
      sitesProPages: [],
      showOpenInfoLicense: false,
      infoLicense: '',
      // imgs icon
      imgOwned: require('@/assets/images/icons/i-owned-by.svg'),
      imgFixed: require('@/assets/images/icons/i-fixed-issues.svg'),
      imgMembership: require('@/assets/images/icons/i-membership.svg'),
      imgCurrent: require('@/assets/images/icons/i-current-errors.svg'),
      imgDomain: require('@/assets/images/icons/i-domain.svg'),
      imgKey: require('@/assets/images/icons/i--key.svg'),
      imgSite: require('@/assets/images/icons/i-site-name.svg'),
      refreshIcon: require('@/assets/images/icons/i-refesh.svg'),
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      proIconNone: require('@/assets/images/icons/i-pro-badge-none.svg'),

      // site
      siteObj: '',
      itemsPagesOverview: [],
      fieldsPagesOverview: [
        {
          key: 'selected',
          label: '',
          thClass: 'thTableSitesSelectShortOverviewErrorsEditSite',
          tdClass: 'tdTableSitesSelectShortOverviewErrorsEditSite',
        },
        {
          key: 'page',
          label: 'Page',
          thClass: 'thTableSitesPageShortOverviewEdit',
          tdClass: 'tdTableSitesPageShortOverviewEdit',
        },
        {
          key: 'pro',
          label: 'SEOcloud copilot',
          thClass: 'thTableSitesShortOverviewErrorsPro',
          tdClass: 'tdTableSitesShortOverviewErrorsPro',
          sortable: true
        },
        {
          key: 'inlinks',
          label: 'Inlinks',
          thClass: 'thTableSitesShortOverviewErrors',
          tdClass: 'tdTableSitesShortOverviewErrors',
          sortable: true
        },
        {
          key: 'errors',
          label: 'Errors',
          thClass: 'thTableSitesShortOverviewErrors',
          tdClass: 'tdTableSitesShortOverviewErrors',
          sortable: true
        }
      ],
      fieldsPagesOverviewDev: [
        {
          key: 'selected',
          label: '',
          thClass: 'thTableSitesSelectShortOverviewErrorsEditSite',
          tdClass: 'tdTableSitesSelectShortOverviewErrorsEditSite',
        },
        {
          key: 'page',
          label: 'Page',
          thClass: 'thTableSitesPageShortOverviewEdit',
          tdClass: 'tdTableSitesPageShortOverviewEdit',
        },
        // {
        //   key: 'pro',
        //   label: 'SEOcloud copilot',
        //   thClass: 'thTableSitesShortOverviewErrorsPro',
        //   tdClass: 'tdTableSitesShortOverviewErrorsPro',
        //   sortable: true
        // },
        {
          key: 'inlinks',
          label: 'Inlinks',
          thClass: 'thTableSitesShortOverviewErrors',
          tdClass: 'tdTableSitesShortOverviewErrors',
          sortable: true
        },
        {
          key: 'errors',
          label: 'Errors',
          thClass: 'thTableSitesShortOverviewErrors',
          tdClass: 'tdTableSitesShortOverviewErrors',
          sortable: true
        }
      ],
      showOpen: false,
      showOpenSiteName: false,
      showOpenSiteDomain: false,
      showOpenComingSoon: false,
      showOpenSidebarProPages: false,

      // vars crawl
      events: ['click', 'load', 'keypress', 'mousemove'],
      crawlID: '',
      crawls: '',
      lastCrawl: '',
      cargaCrawl: false,
      body: {
        url: '',
        type: 'full',
        forceNotFound: 'yes',
        pageSize: 999999999,
        id: 'seo-shops-full-' + Date.now(),
        takeScreenshots: 'no',
        source: 'seo-cloud-full-' + Date.now(),
        triggeredBy: 'test triggered',
        screenshotType: 'test',
        screenshotComment: 'test comment por julio a1',
        stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
        ignoreIndexNoFollow: "yes"
      },
      initDate: 0,
      urlSite: '',
      counterPages: 0,
      lastCrawlTotalRequest: 1,
      counterErrores: 0,
      execCrawl: false,
      refreshPage: true,
      crawlRunning: false,
      loaderPages: true,
      // counterErrores: 0,
      // execCrawl: false,
      // refreshPage: true,
      searchSite: '',
      counterRowsSelected: 0,
      dateRunCrawl: 0,
      incompleteCrawl: false,
      listProPages: [],
      firstLoadDOM: false,
      processingFiltered: false,
      keyVisible: false,
      timerInfiniteErrors: null,

      // new vars migration db firebase
      SiteID: '',
      siteWebsiteURL: '',
      siteHomepage: '',
      Mostrecentfullcrawl: '',
      visibleRows: [],
      sortBy: 'id',
      counterContentTab: 0,
      counterErrorsTab: 0,
      countermT: 0,
      countermD: 0,
      counterh1: 0,
      counterh2: 0,
      countermI: 0,
      counterwC: 0,
      counterst: 0,
      countermA: 0,
      counterdL: 0,
      counteruC: 0,
      activeCrawl: '',
      domainPrefix: '',
      selectMode: 'multi',
      selected: [],
      loadingInfiniteScroll: false,
      idsite: '',
      showOpenActiveSite: false,
      showOpenArchiveSite: false,
      keyID: '',
      logicOwner: '',

      // cron job 10 seconds
      timer: null,
      processRequests: 1,
      loadingNewItemsPagesOverview: [],
      statusHome: 200,
      listPagesSidebars: [],
      totalErrors: 0
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'Edit Site' },
    ],
  },
  setup() {
    return {
      avatarText,
    }
  },
  watch: {
    activeCrawl(val) {
      if(val) {
        db.collection('Crawls').doc(val)
        .onSnapshot(docCrawl => {
          if(docCrawl.exists) {
            // console.log('snapshot listen')
            // docCrawl.data().Requests === 1 || docCrawl.data().Requests === 0 ? this.crawls = {} : null
            this.crawlRunning = true
            this.crawls.estatus = docCrawl.data().status
            var arrayMasterPages = docCrawl.data().pages && docCrawl.data().pages.length >= 1 ? docCrawl.data().pages : []
            var filterStatusPages = arrayMasterPages.filter(ele => ele.status !== null)
            // this.crawls.requests = !filterStatusPages.length ? 1 : filterStatusPages.length
            this.processRequests = !filterStatusPages.length ? 1 : filterStatusPages.length
            this.crawls.availableURL = docCrawl.data().founds.length

            docCrawl.data().Requests === 1 ? this.itemsPagesOverview = [] : null
            if(this.itemsPagesOverview.length === 0) {
              this.itemsPagesOverview.unshift({
                id: 0,
                selected: false,
                page: 'home',
                title: 'home',
                pro: true,
                url: 'Homepage',
                inlinks: 0,
                inlinksList: [],
                errors: 0,
                name: 'a',
                cUrl: this.siteHomepage
                // exist: true,
              })
            }

            if(docCrawl.data().status !== 'running') {
              this.crawlRunning = false
              this.crawls = {}
              this.crawls.estatus = docCrawl.data().status
              this.crawls.availableURL = docCrawl.data().founds.length
              // console.log(docCrawl.data().status, this.crawls)
              // this.itemsPagesOverview = []
              // this.getCrawlEvents()
              var filterP = arrayMasterPages.filter(ele => ele.url === this.siteHomepage)
              filterP.forEach(itemPage => {
                this.statusHome = itemPage.status
              })
              var filterH = this.itemsPagesOverview.filter(ele => ele.cUrl === this.siteHomepage)
              filterH.forEach(itemPage => {
                itemPage.status = this.statusHome
              })
            }
          }
        })
      }
    },
    showOpenSidebarProPages(val) {
      // setTimeout(() => {
      if(!val || val === 'false') {
        localStorage.setItem('viewSPro', 'false')
      }

      if(val) {
        // var atomItems = this.itemsPagesOverview
        this.listPagesSidebars = []
        this.itemsPagesOverview.forEach(ele => {
          if(ele.status !== 301 && ele.status !== 302 && ele.status !== 404) {
            // ele.cUrl.includes('/capabilities/brand-safety') ? console.log(ele) : console.log('first')
            this.listPagesSidebars.push(ele)
          }
        })
        var counterId = this.listPagesSidebars.length - 1
        this.sitesProPages.forEach(item => {
          var filterPagePro = this.listPagesSidebars.filter(ele => ele.cUrl === item)
          if(filterPagePro.length === 0) {
            if(this.itemsPagesOverview.filter(ele => ele.cUrl === item && ele.status === 200).length >= 1) {
              // console.log(item)
              counterId += 1
              this.listPagesSidebars.push({
                id: counterId,
                url: item,
                cUrl: item,
                title: item,
                cTitle: item,
                page: item,
                pro: true,
                inlinks: 0,
                inlinksList: [],
                errors: 0,
                name: item,
                status: 200,
                redirectTo: '',
                selectedInPro: true
              })
            }
          }
        })
      } else {
        this.listPagesSidebars = []
      }
      // }, 1000)
    },
  },
  mounted() {
    // this.chargeCrawl()

    // this.events.forEach(event => {
    //   window.addEventListener(event, this.getCrawlEvents)
    // })
    this.timer = setInterval(() => {
      this.getCrawlEvents()
    }, 6000)

    this.timerInfiniteErrors = setInterval(() => {
      // if((this.crawls && this.crawls.estatus === 'running') || this.crawlRunning) {
        this.constructInfiniteErrors()
      // }
    }, 600)
  },
  destroyed() {
    // this.events.forEach(event => {
    //   window.removeEventListener(event, this.getCrawlEvents)
    // })
    clearInterval(this.timer)
    clearInterval(this.timerInfiniteErrors)
  },
  created() {
    this.loadingNewItemsPagesOverview = []
    this.loadingInfiniteScroll = false
    this.firstLoadDOM = false
    this.siteObj = []
    this.itemsPagesOverview = []
    // this.SiteID = ''
    // this.Mostrecentfullcrawl = ''
    this.SiteID = ''
    this.siteWebsiteURL = ''
    this.siteHomepage = ''
    this.Mostrecentfullcrawl = ''
    this.activeCrawl = ''
    this.crawls = {}
    this.refreshPage = true
    this.crawlRunning = false
    this.processRequests = 1
    this.listPagesSidebars = []
    this.keyID = ''
    this.keyVisible = false
    this.logicOwner = ''
    this.sitesProPages = []

    this.isDevSite = false
    this.showOpenComingSoon = false
    var urlWindow = window.location.href
    var urlWindowTransform = new URL(urlWindow)
    var urlWindowDomain = urlWindowTransform.hostname
    // console.log(urlWindowDomain)
    if(urlWindowDomain === 'app.seocloud.ai' || urlWindowDomain === 'localhost') {
      // this.isDevSite = true
    }
    // this.siteObj = this.itemsPagesOverview.filter(ele => ele.id === this.$route.params.id)
    const user = auth.currentUser
    if(user) {
      db.collection('Users').doc(user.uid).get()
      .then(userDoc => {
        const getFirstUser = userDoc.id.slice(0, 4);
        const getFirstSite = this.$route.params.id.slice(0, 4);
        this.keyID = getFirstUser + getFirstSite

        db.collection('Users').doc(user.uid).collection('projects').doc(this.$route.params.project).get()
        .then(eleUserProject => {
          this.userData = userDoc.data()
          this.userData.id = userDoc.id
          this.userData.foto = userDoc.data().foto ? userDoc.data().foto : ''
          this.userData.name = userDoc.data().nombre ? userDoc.data().nombre : ''
          this.userData.status = userDoc.data().status ? userDoc.data().status : ''
          this.userData.email = userDoc.data().email ? userDoc.data().email : ''
          this.userData.teams = userDoc.data().teams ? userDoc.data().teams : ''
          
          var teamsVar = userDoc.data().teams && userDoc.data().teams.length ? userDoc.data().teams : []
          if(teamsVar.includes(this.$route.params.project)) {
            if(this.$route.params.project) {
              db.collection('Sites').doc(this.$route.params.id)
              .onSnapshot(site => {
              dbErrors.collection('Errors').where('SiteID', '==',this.$route.params.id).where('recent_crawl_id', '==', site.data().mostRecentCrawl).get()
              .then(docsExternals => {
                var counterFixes = 0
                docsExternals.forEach(ele => {
                  var changesPage = ele.data().changes ? ele.data().changes : []
                  var filterChange = changesPage.filter(ele => ele.user.uid === userDoc.id)
                  
                  counterFixes += filterChange.length
                })

                  db.collection('Sites_cache').doc(this.$route.params.id).get()
                  .then(siteCache => {
                    var ownerVar = site.data().owner && site.data().owner.uid ? site.data().owner : ''
                    this.logicOwner = ownerVar && ownerVar.uid ? (this.userData.uid === ownerVar.uid ? 'me' : 'other') : 'other'
                    this.sitesProPages = site.data().proPages ? site.data().proPages : []
                    this.siteHomepage = site.data().homepage

                    db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.$route.params.id).get()
                    .then(siteProject => {
                      this.totalErrors = siteProject.data().totalErrors

                      if(siteProject.data().plan === 'free' || siteProject.data().plan === 'Free' || !siteProject.data().plan) {
                        this.infoLicense = {
                          id: siteProject.id,
                          img: siteProject.data().thumbnail ? siteProject.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                          name: siteProject.data().name ? siteProject.data().name : siteProject.data().WebsiteURL,
                          domainFixed: siteProject.data().WebsiteURL,
                          project: eleUserProject.data().name,
                          statusSite: siteProject.data().status,
                          domain: siteProject.data().homepage,
                          projectid: eleUserProject.data().id,
                          color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                          own: 'me',
                          invitation: '',
                          imgSite: siteProject.data().thumbnail ? siteProject.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                          plan: 'Free',
                          permission: 'owner',
                          amount: 'free',
                          nextDue: siteProject.data().due ? moment(siteProject.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : '-',
                          nextDueCancel: siteProject.data().due ? moment(siteProject.data().due*1000).add(1, 'month').format('ll') : '-',
                          // nextDueCancel: siteProject.data().due ? moment(siteProject.data().due*1000).format('MMM DD') : '-',
                          cancelSub: siteProject.data().cancelSub ? siteProject.data().cancelSub : false,
                          status: 'active',
                          payments: 'View Invoice',
                          customerId: userDoc.data().customerId ? userDoc.data().customerId : '',
                          customerIdSite: siteProject.data().customerId ? siteProject.data().customerId : '',
                          subscriptionId: siteProject.data().subscriptionId ? siteProject.data().subscriptionId : '',
                          planType: siteProject.data().planType ? parseInt(siteProject.data().planType, 10) : '',
                          planSite: siteProject.data().plan ? siteProject.data().plan : '',
                          paidRecurrency: siteProject.data().paidRecurrency ? siteProject.data().paidRecurrency : ''
                        }
                      } else if(siteProject.data().plan === 'basic' || siteProject.data().plan === 'Basic' || siteProject.data().plan === 'pro' || siteProject.data().plan === 'Pro' || siteProject.data().plan === 'custom' || siteProject.data().plan === 'Custom') {
                        this.infoLicense = {
                          id: siteProject.id,
                          img: siteProject.data().thumbnail ? siteProject.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                          name: siteProject.data().name ? siteProject.data().name : siteProject.data().WebsiteURL,
                          domainFixed: siteProject.data().WebsiteURL,
                          project: eleUserProject.data().name,
                          statusSite: siteProject.data().status,
                          domain: siteProject.data().homepage,
                          projectid: eleUserProject.data().id,
                          color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                          own: 'me',
                          invitation: '',
                          imgSite: siteProject.data().thumbnail ? siteProject.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                          plan: 'Pro',
                          permission: 'owner',
                          amount: '$20.00',
                          nextDue: siteProject.data().due ? moment(siteProject.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY'),
                          nextDueCancel: siteProject.data().due ? moment(siteProject.data().due*1000).add(1, 'month').format('ll') : '-',
                          cancelSub: siteProject.data().cancelSub ? siteProject.data().cancelSub : false,
                          status: 'active',
                          payments: 'View Invoice',
                          customerId: userDoc.data().customerId ? userDoc.data().customerId : '',
                          customerIdSite: siteProject.data().customerId ? siteProject.data().customerId : '',
                          subscriptionId: siteProject.data().subscriptionId ? siteProject.data().subscriptionId : '',
                          planType: siteProject.data().planType ? parseInt(siteProject.data().planType, 10) : '',
                          planSite: siteProject.data().plan ? siteProject.data().plan : '',
                          paidRecurrency: siteProject.data().paidRecurrency ? siteProject.data().paidRecurrency : '',
                          pages: siteProject.data().planType ? siteProject.data().planType : ''
                        }
                      }

                      if(siteProject.data().status === 'archive') {
                        this.$router.push({ name: 'archive-sites' })
                      } else {
                        window.document.title = 'Edit site - ' + (siteProject.data().name ? siteProject.data().name : siteProject.data().homepage) + ' - SEOcloud'

                        this.siteObj = []
                        // console.log(site.data().name, siteProject.data().name)
                        this.siteObj.push({
                          imgSite: siteProject.data().thumbnail ? siteProject.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                          name: siteProject.data().name ? siteProject.data().name : site.data().name ? site.data().name : siteProject.data().homepage,
                          domain: siteProject.data().homepage,
                          domainFix: siteProject.data().WebsiteURL,
                          currentErrors: '0',
                          fixedIssues: counterFixes ? counterFixes : '0',
                          key: siteProject.id,
                          alerts: '3',
                          membership: `${siteProject.data().plan ? siteProject.data().plan : 'Free'} license`,
                          ownedBy: site.data().owner && site.data().owner.uid ? site.data().owner : '',
                          label: '',
                          color: '',
                          id: siteProject.id,
                          projectid: this.$route.params.project,
                          own: 'me',
                          status: siteProject.data().status
                        })
                      }
                      
                      if(site.data().mostRecentCrawlStatus === 'running') {
                        this.crawlID = site.data().mostRecentCrawl
                        this.activeCrawl = site.data().mostRecentCrawl
                        this.crawls.id = site.data().mostRecentCrawl
                        this.crawls.estatus = 'running'
                        this.activeCrawlFunction()
                      } else if(site.data().mostRecentCrawlStatus === 'terminated' || site.data().mostRecentCrawlStatus === 'incomplete' || site.data().mostRecentCrawlStatus === 'endSuccess'  || site.data().mostRecentCrawlStatus === 'timeout') {
                        this.crawlRunning = false
                        this.crawlID = site.data().mostRecentCrawl
                        this.crawls.estatus === 'running' ? this.crawls = {} : ''
                        this.crawls.id = site.data().mostRecentCrawl
                        this.crawls.estatus = site.data().mostRecentCrawlStatus
                        this.crawls.availableURL = site.data().totalPages
                        this.crawls.date = moment(site.data().mostRecentCrawlDate).format("Do MMMM YYYY")
                        var arrayPages = site.data().pages && site.data().pages.length >= 1 ? site.data().pages : []
                        var totalRequest = arrayPages.filter(ele => ele.status !== null)
                        this.crawls.requests = totalRequest.length
                        var inlinks = siteCache.data().inlinks && siteCache.data().inlinks.length ? siteCache.data().inlinks : []
                        var proPages = site.data().proPages && site.data().proPages.length ? site.data().proPages : []
                        var docsObjErrors = site.data().errorsTypePage && site.data().errorsTypePage.length >= 1 ? site.data().errorsTypePage : []
                        var arrayProPages = []
                        var array200Pages = []
                        var array200XML = []
                        var array302Pages = []
                        var array301Pages = []
                        var array404Pages = []
                        var arrayTimeoutPages = []
                        var arrayOtherPages = []
                        var idListPage = 0
                        // this.totalErrors = site.data().totalErrors
                        arrayPages.forEach(itemPage => {
                          if((itemPage.url.includes('.xml') && itemPage.status === 200) || (itemPage.status && !itemPage.url.includes('.xml'))) {
                            var URLactual = new URL(itemPage.url)
                            var filterPage = this.itemsPagesOverview.filter(ele => ele.cUrl === itemPage.url)
                            var filterHomePage = this.itemsPagesOverview.filter(ele => ele.id === 0)
                            // var idListPage = incrementalIdPages
                            var filterInlinksPage = inlinks.filter(item => item.link === itemPage.url)
                            var filterPro = proPages.filter(ele => ele === itemPage.url)
                            var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === itemPage.url)
                            var filterObjErrors = []
                            var counterErrUrl = 0
                            filterObjErrorsFilter.forEach(ele => {
                              if(ele.errors && ele.errors.length) {
                                ele.errors.forEach(eleCounter => {
                                  counterErrUrl += eleCounter.counter
                                  // if(!itemPage.url.includes('.xml') && itemPage.status === 200) {
                                  //   this.totalErrors += eleCounter.counter
                                  // }
                                })
                              }
                            })
                            filterObjErrors.push({counter: counterErrUrl})
                            var urlcrawl = new URL(this.siteHomepage)
                            var urlProtocol = urlcrawl.protocol
                            var URLdomainFound = urlcrawl.host
                            var URLwwwFound = ''
                            var URLwithoutWWWFound = ''
                            if(URLdomainFound.includes('www.')) {
                              URLwwwFound = urlProtocol + '//' + URLdomainFound
                              URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound.slice(4)
                            } else {
                              URLwwwFound = urlProtocol + '//' + 'www.'+URLdomainFound
                              URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound
                            }

                            if(URLactual.pathname === '/' && filterHomePage.length === 0) {
                              itemPage.status ? this.statusHome = itemPage.status : null
                              var filterInlinksPageHome = inlinks.filter(item => item.link === this.siteHomepage || item.link === this.siteHomepage+ '/' || item.link === URLwwwFound || item.link === URLwithoutWWWFound || item.link === URLwwwFound + '/' || item.link === URLwithoutWWWFound + '/')
                              this.itemsPagesOverview.unshift({
                                id: 0,
                                selected: false,
                                page: 'home',
                                title: itemPage.title ? itemPage.title : 'home',
                                cTitle: itemPage.title ? itemPage.title : '',
                                pro: true,
                                url: 'Homepage',
                                // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                inlinks: filterInlinksPageHome.length,
                                inlinksList: filterInlinksPageHome,
                                errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                name: 'a',
                                cUrl: itemPage.url,
                                status: itemPage.status,
                                redirectTo: itemPage.redirectTo
                                // exist: true,
                              })
                            } else if(filterPage.length === 0) {
                              idListPage += 1
                              if(filterPro.length >= 1) {
                                var filterP = arrayProPages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  arrayProPages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: true,
                                    url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 200) {
                                if(itemPage.url.includes('.xml')) {
                                  var filterP = array200XML.filter(ele => ele.cUrl === itemPage.url)
                                  if(filterP.length) {
                                    idListPage -= 1
                                  } else {
                                    array200XML.push({
                                      id: idListPage,
                                      selected: false,
                                      page: URLactual.pathname,
                                      title: itemPage.title ? itemPage.title : URLactual.pathname,
                                      cTitle: itemPage.title ? itemPage.title : '',
                                      pro: itemPage.pro ? true : false,
                                      url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                      // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                      inlinks: filterInlinksPage.length,
                                      inlinksList: filterInlinksPage,
                                      errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                      name: URLactual.pathname,
                                      cUrl: itemPage.url,
                                      status: itemPage.status,
                                      redirectTo: itemPage.redirectTo
                                      // exist: true,
                                    })
                                  }
                                } else {
                                  var filterP = array200Pages.filter(ele => ele.cUrl === itemPage.url)
                                  if(filterP.length) {
                                    idListPage -= 1
                                  } else {
                                    array200Pages.push({
                                      id: idListPage,
                                      selected: false,
                                      page: URLactual.pathname,
                                      title: itemPage.title ? itemPage.title : URLactual.pathname,
                                      cTitle: itemPage.title ? itemPage.title : '',
                                      pro: itemPage.pro ? true : false,
                                      url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                      // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                      inlinks: filterInlinksPage.length,
                                      inlinksList: filterInlinksPage,
                                      errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                      name: URLactual.pathname,
                                      cUrl: itemPage.url,
                                      status: itemPage.status,
                                      redirectTo: itemPage.redirectTo
                                      // exist: true,
                                    })
                                  }
                                }
                              } else if(itemPage.status === 302) {
                                var filterP = array302Pages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array302Pages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 301) {
                                var filterP = array301Pages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array301Pages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 404) {
                                var filterP = array404Pages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array404Pages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 'timeout') {
                                var filterP = arrayTimeoutPages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  arrayTimeoutPages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else {
                                var filterP = arrayOtherPages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  arrayOtherPages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              }
                            }
                          }
                        })

                        var condicionParaOrdenar = (pageA, pageB) => {
                          return pageB.inlinks - pageA.inlinks;
                        }
                        arrayProPages.sort(condicionParaOrdenar)
                        array200Pages.sort(condicionParaOrdenar)
                        array200XML.sort(condicionParaOrdenar)
                        array302Pages.sort(condicionParaOrdenar)
                        array301Pages.sort(condicionParaOrdenar)
                        array404Pages.sort(condicionParaOrdenar)
                        arrayTimeoutPages.sort(condicionParaOrdenar)
                        arrayOtherPages.sort(condicionParaOrdenar)
                        var incrementalIdPages = 0

                        arrayProPages.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })
                        
                        array200Pages.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })

                        array302Pages.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })

                        array200XML.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })

                        array301Pages.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })

                        array404Pages.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })

                        arrayTimeoutPages.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })

                        arrayOtherPages.forEach(ele => {
                          incrementalIdPages += 1
                          this.itemsPagesOverview.push({
                            id: incrementalIdPages,
                            selected: ele.selected,
                            page: ele.page,
                            title: ele.title,
                            cTitle: ele.cTitle,
                            pro: ele.pro,
                            url: ele.url,
                            inlinks: ele.inlinks,
                            inlinksList: ele.inlinksList,
                            errors: ele.errors,
                            name: ele.name,
                            cUrl: ele.cUrl,
                            status: ele.status,
                            redirectTo: ele.redirectTo
                            // exist: true,
                          })
                        })
                      }
                      this.loaderPages = false
                    })
                  })
              })
              })

              this.permission = 'owner'
            }
          } else {
            this.$router.push({ name: 'projects' })
          }
        })
      })
    }
  },
  methods: {
    constructInfiniteErrors() {
      // if(this.tabActive === 'overview') {
        // horizontal scroll
        this.$nextTick(() => {
          $(".top-scroll-horizontal-tabs-errs").scroll(() => {
            // console.log(1)
            $(".vgt-table").scrollLeft($(".top-scroll-horizontal-tabs-errs").scrollLeft());
          });

          // $(".tabs-errs > .vgt-table").scroll(() => {
          //   console.log(2)
          //   $(".top-scroll-horizontal-tabs-errs").scrollLeft($(".vgt-table").scrollLeft());
          // });
        });

        setTimeout(() => {
          const miElemento = document.querySelector('.tabs-errs .vgt-table');
          if(miElemento) {
            miElemento.addEventListener('scroll', () => {
              // console.log(2);
              document.querySelector('.top-scroll-horizontal-tabs-errs').scrollLeft = document.querySelector('.tabs-errs .vgt-table').scrollLeft;
            });
          }
        }, 1200)
      // }

      const primerElemento = document.getElementById('tab-err-sc1');
      const segundoElemento = document.getElementById('tab-err-sc2');

      if(primerElemento) {
        const ancho = primerElemento.offsetWidth; // Obtiene el ancho del primer elemento
        segundoElemento.style.width = ancho + 'px';
      }

      // loadingNewItemsPagesOverview
      var listElm = document.querySelector('#app');
      let bottomOfWindow = document.documentElement.scrollTop + listElm.clientHeight >= listElm.scrollHeight - 200
      // if(this.selectedFilteredPages.length === 0) {
        if(this.itemsPagesOverview.length >= 1) {
          if(bottomOfWindow) {
            if(!this.loadingInfiniteScroll && this.itemsPagesOverview.length !== this.loadingNewItemsPagesOverview.length) {
              this.loadingInfiniteScroll = true
              var counterFound = 0
              this.itemsPagesOverview.forEach(ele => {
                var alreadyAdd = this.loadingNewItemsPagesOverview.filter(item => item.cUrl === ele.cUrl)
                if(alreadyAdd.length === 0) {
                  if(counterFound <= 20) {
                    counterFound += 1
                    this.loadingNewItemsPagesOverview.push(ele)
                  }
                }
              })

              this.loadingInfiniteScroll = false
            }
          }
        }
      // }
    },
    toggleVisibleKey() {
      this.keyVisible ? this.keyVisible = false : this.keyVisible = true
    },
    getCrawlEvents() {
      if(this.$route.params.id) {
        db.collection('Sites').doc(this.$route.params.id).get()
        .then(site => {
        db.collection('Sites_cache').doc(this.$route.params.id).get()
        .then(siteCache => {
          !site.data().mostRecentCrawl ? this.crawls = '' : ''

          if(site.data().mostRecentCrawlStatus === 'running') {
            this.crawlID = site.data().mostRecentCrawl
            this.activeCrawl = site.data().mostRecentCrawl
            this.crawls.id = site.data().mostRecentCrawl
            this.crawls.estatus = 'running'
            this.activeCrawlFunction()
          } else if(site.data().mostRecentCrawlStatus === 'terminated' || site.data().mostRecentCrawlStatus === 'incomplete' || site.data().mostRecentCrawlStatus === 'endSuccess'  || site.data().mostRecentCrawlStatus === 'timeout') {
            this.crawlRunning = false
            this.crawlID = site.data().mostRecentCrawl
            this.crawls.estatus === 'running' ? this.crawls = {} : ''
            this.crawls.id = site.data().mostRecentCrawl
            this.crawls.estatus = site.data().mostRecentCrawlStatus
            this.crawls.availableURL = site.data().totalPages
            this.crawls.date = moment(site.data().mostRecentCrawlDate).format("Do MMMM YYYY")
            var arrayPages = site.data().pages && site.data().pages.length >= 1 ? site.data().pages : []
            var totalRequest = arrayPages.filter(ele => ele.status !== null)
            this.crawls.requests = totalRequest.length
            var inlinks = siteCache.data().inlinks && siteCache.data().inlinks.length ? siteCache.data().inlinks : []
            var proPages = site.data().proPages && site.data().proPages.length ? site.data().proPages : []
            var docsObjErrors = site.data().errorsTypePage && site.data().errorsTypePage.length >= 1 ? site.data().errorsTypePage : []
            var arrayProPages = []
            var array200Pages = []
            var array200XML = []
            var array302Pages = []
            var array301Pages = []
            var array404Pages = []
            var arrayTimeoutPages = []
            var arrayOtherPages = []
            var idListPage = 0
            // this.totalErrors = site.data().totalErrors
            this.itemsPagesOverview = []
            arrayPages.forEach(itemPage => {
              if((itemPage.url.includes('.xml') && itemPage.status === 200) || (itemPage.status && !itemPage.url.includes('.xml'))) {
                var URLactual = new URL(itemPage.url)
                var filterPage = this.itemsPagesOverview.filter(ele => ele.cUrl === itemPage.url)
                var filterHomePage = this.itemsPagesOverview.filter(ele => ele.id === 0)
                // var idListPage = incrementalIdPages
                var filterInlinksPage = inlinks.filter(item => item.link === itemPage.url)
                var filterPro = proPages.filter(ele => ele === itemPage.url)
                var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === itemPage.url)
                var filterObjErrors = []
                var counterErrUrl = 0
                filterObjErrorsFilter.forEach(ele => {
                  if(ele.errors && ele.errors.length) {
                    ele.errors.forEach(eleCounter => {
                      counterErrUrl += eleCounter.counter
                      // if(!itemPage.url.includes('.xml') && itemPage.status === 200) {
                      //   this.totalErrors += eleCounter.counter
                      // }
                    })
                  }
                })
                filterObjErrors.push({counter: counterErrUrl})
                var urlcrawl = new URL(this.siteHomepage)
                var urlProtocol = urlcrawl.protocol
                var URLdomainFound = urlcrawl.host
                var URLwwwFound = ''
                var URLwithoutWWWFound = ''
                if(URLdomainFound.includes('www.')) {
                  URLwwwFound = urlProtocol + '//' + URLdomainFound
                  URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound.slice(4)
                } else {
                  URLwwwFound = urlProtocol + '//' + 'www.'+URLdomainFound
                  URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound
                }

                if(URLactual.pathname === '/' && filterHomePage.length === 0) {
                  var filterInlinksPageHome = inlinks.filter(item => item.link === this.siteHomepage || item.link === this.siteHomepage+ '/' || item.link === URLwwwFound || item.link === URLwithoutWWWFound || item.link === URLwwwFound + '/' || item.link === URLwithoutWWWFound + '/')
                  this.itemsPagesOverview.unshift({
                    id: 0,
                    selected: false,
                    page: 'home',
                    title: itemPage.title ? itemPage.title : 'home',
                    cTitle: itemPage.title ? itemPage.title : '',
                    pro: true,
                    url: 'Homepage',
                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                    inlinks: filterInlinksPageHome.length,
                    inlinksList: filterInlinksPageHome,
                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                    name: 'a',
                    cUrl: itemPage.url,
                    status: this.statusHome ? this.statusHome : itemPage.status,
                    redirectTo: itemPage.redirectTo
                    // exist: true,
                  })
                } else if(filterPage.length === 0) {
                  idListPage += 1
                  if(filterPro.length >= 1) {
                    var filterP = arrayProPages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      arrayProPages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: true,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 200) {
                    if(itemPage.url.includes('.xml')) {
                      var filterP = array200XML.filter(ele => ele.cUrl === itemPage.url)
                      if(filterP.length) {
                        idListPage -= 1
                      } else {
                        array200XML.push({
                          id: idListPage,
                          selected: false,
                          page: URLactual.pathname,
                          title: itemPage.title ? itemPage.title : URLactual.pathname,
                          cTitle: itemPage.title ? itemPage.title : '',
                          pro: itemPage.pro ? true : false,
                          url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                          // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                          inlinks: filterInlinksPage.length,
                          inlinksList: filterInlinksPage,
                          errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                          name: URLactual.pathname,
                          cUrl: itemPage.url,
                          status: itemPage.status,
                          redirectTo: itemPage.redirectTo
                          // exist: true,
                        })
                      }
                    } else {
                      var filterP = array200Pages.filter(ele => ele.cUrl === itemPage.url)
                      if(filterP.length) {
                        idListPage -= 1
                      } else {
                        array200Pages.push({
                          id: idListPage,
                          selected: false,
                          page: URLactual.pathname,
                          title: itemPage.title ? itemPage.title : URLactual.pathname,
                          cTitle: itemPage.title ? itemPage.title : '',
                          pro: itemPage.pro ? true : false,
                          url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                          // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                          inlinks: filterInlinksPage.length,
                          inlinksList: filterInlinksPage,
                          errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                          name: URLactual.pathname,
                          cUrl: itemPage.url,
                          status: itemPage.status,
                          redirectTo: itemPage.redirectTo
                          // exist: true,
                        })
                      }
                    }
                  } else if(itemPage.status === 302) {
                    var filterP = array302Pages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      array302Pages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 301) {
                    var filterP = array301Pages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      array301Pages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 404) {
                    var filterP = array404Pages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      array404Pages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 'timeout') {
                    var filterP = arrayTimeoutPages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      arrayTimeoutPages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else {
                    var filterP = arrayOtherPages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      arrayOtherPages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  }
                } else {
                  if(filterPro.length >= 1) {
                    filterPage.forEach(elePro => {
                      elePro.pro = true
                      elePro.status = itemPage.status
                    })
                  } else if(URLactual.pathname === '/') {
                    filterPage.forEach(elePro => {
                      // elePro.pro = true
                      elePro.status = itemPage.status
                    })
                  }
                }
              }
            })

            var condicionParaOrdenar = (pageA, pageB) => {
              return pageB.inlinks - pageA.inlinks;
            }
            arrayProPages.sort(condicionParaOrdenar)
            array200Pages.sort(condicionParaOrdenar)
            array200XML.sort(condicionParaOrdenar)
            array302Pages.sort(condicionParaOrdenar)
            array301Pages.sort(condicionParaOrdenar)
            array404Pages.sort(condicionParaOrdenar)
            arrayTimeoutPages.sort(condicionParaOrdenar)
            arrayOtherPages.sort(condicionParaOrdenar)
            var incrementalIdPages = 0

            // array200Pages.push({
            //   pro: false,
            //   cUrl: 'https://viventi.com.gt/test2',
            //   status: 200,
            //   id: 1,
            //   selected: false,
            //   page: 'test',
            //   title: 'test',
            //   cTitle: 'test',
            //   url: '/test2',
            //   inlinks: 0,
            //   inlinksList: [],
            //   errors: 0,
            //   name: 'test',
            //   redirectTo: null
            // })

            arrayProPages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })
            
            array200Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array302Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array200XML.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array301Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array404Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            arrayTimeoutPages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            arrayOtherPages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            // console.log('flag Cron')
            // var condicionParaOrdenar1 = (pageA, pageB) => {
            //   if(pageB.pro || pageA.pro) {
            //     return pageB.pro - pageA.pro;
            //   }
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar1)

            // this.itemsPagesOverview.sort(function (a, b) {
            //   // if(!a.pro && !b.pro) {
            //     // if(!a.cUrl.includes('.xml') && !b.cUrl.includes('.xml')) {
            //     //   if(a.cUrl.includes('.xml')) {
            //     //     console.log('first')
            //     //   }

            //       if(parseInt(a.status, 10) >= 1 || parseInt(b.status, 10) >= 1) {
            //         if((parseInt(a.status, 10) > parseInt(b.status, 10)) && (!a.cUrl.includes('.xml') || !b.cUrl.includes('.xml'))) {
            //           return 1;
            //         }
            //         if((parseInt(a.status, 10) < parseInt(b.status, 10)) && (!a.cUrl.includes('.xml') || !b.cUrl.includes('.xml'))) {
            //           return -1;
            //         }
            //         return 0;
            //       }
            //     // }
            //   // }
            // })

            // this.itemsPagesOverview.sort(function (a, b) {
            //   if(!a.pro && !b.pro) {
            //     if(!a.cUrl.includes('.xml') || !b.cUrl.includes('.xml')) {
            //     if(parseInt(a.inlinks, 10) >= 1 || parseInt(b.inlinks, 10) >= 1) {
            //       if(parseInt(a.inlinks, 10) < parseInt(b.inlinks, 10)) {
            //         return 1;
            //       }
            //       if(parseInt(a.inlinks, 10) > parseInt(b.inlinks, 10)) {
            //         return -1;
            //       }
            //       return 0;
            //     }
            //     }
            //   }
            // })

            // var condicionParaOrdenar2 = (pageA, pageB) => {
            //   // console.log(pageA.status,pageB.status)
            //   if(!pageB.pro && !pageA.pro) {
            //     // if(pageA.cUrl === 'https://viventi.com.gt/test2') {
            //     //   console.log(pageB.cUrl)
            //     // }

            //     if(!pageA.cUrl.includes('.xml') || !pageB.cUrl.includes('.xml')) {
            //       // console.log(pageA.cUrl,pageB.cUrl)
            //       return pageA.status - pageB.status;
            //     }
            //   }
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar2)

            // var condicionParaOrdenar3 = (pageA, pageB) => {
            //   // console.log(pageA.status,pageB.status)
            //   if(!pageB.pro && !pageA.pro) {
            //     if(pageA.cUrl.includes('.xml') && pageB.cUrl.includes('.xml')) {
            //       return pageA.status - pageB.status;
            //     }
            //   }
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar3)

            // var condicionParaOrdenar4 = (pageA, pageB) => {
            //   // if(!pageB.pro && !pageA.pro) {
            //   if(!pageB.pro && !pageA.pro) {
            //     // if((pageB.status === 200 && pageA.status === 200) && (!pageB.cUrl.includes('.xml') && !pageA.cUrl.includes('.xml'))) {
            //     if((pageA.status === 200 && pageB.status === 200) && (!pageA.cUrl.includes('.xml') && !pageB.cUrl.includes('.xml'))) {
            //       // console.log('first')
            //       return pageB.inlinks - pageA.inlinks
            //     }
            //   }
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar4)
            // console.log(this.itemsPagesOverview)
            // console.log('first')
          }
          this.firstLoadDOM = true
          this.loaderPages = false
        })
        })
      }
    },
    activeCrawlFunction() {
      if(this.activeCrawl) {
        // console.log(val)
        db.collection('Sites').doc(this.SiteID).get()
        .then(site => {
        db.collection('Crawls').doc(this.activeCrawl).get()
        .then(docCrawl => {
          if(docCrawl.exists) {
            // console.log('function listen')
            // docCrawl.data().Requests === 1 || docCrawl.data().Requests === 0 ? this.crawls = {} : null
            this.crawls.estatus = docCrawl.data().status
            var arrayMasterPages = docCrawl.data().pages && docCrawl.data().pages.length >= 1 ? docCrawl.data().pages : []
            var proPagesArray = docCrawl.data().proPages
            var inlinks = docCrawl.data().inlinks && docCrawl.data().inlinks.length ? docCrawl.data().inlinks : []
            var docsObjErrors = site.data().errorsTypePage && site.data().errorsTypePage.length >= 1 ? site.data().errorsTypePage : []
            this.itemsPagesOverview = []

            if(this.itemsPagesOverview.length === 0) {
              var filterInlinksPageHome = inlinks.filter(item => item.link === this.siteHomepage || item.link === this.siteHomepage + '/')
              this.itemsPagesOverview.unshift({
                id: 0,
                selected: false,
                page: 'home',
                title: 'home',
                pro: true,
                url: 'Homepage',
                inlinks: filterInlinksPageHome.length,
                inlinksList: filterInlinksPageHome,
                errors: 0,
                name: 'a',
                cUrl: this.siteHomepage
                // exist: true,
              })
            }

            var arrayProPages = []
            var array200Pages = []
            var array200XML = []
            var array302Pages = []
            var array301Pages = []
            var array404Pages = []
            var arrayTimeoutPages = []
            var arrayOtherPages = []
            var idListPage = 0
            // this.totalErrors = site.data().totalErrors
            arrayMasterPages.forEach(itemPage => {
              if((itemPage.url.includes('.xml') && itemPage.status === 200) || (itemPage.status && !itemPage.url.includes('.xml'))) {
                var URLactual = new URL(itemPage.url)
                var filterPage = this.itemsPagesOverview.filter(ele => ele.cUrl === itemPage.url)
                var filterHomePage = this.itemsPagesOverview.filter(ele => ele.id === 0)
                // var idListPage = incrementalIdPages
                var filterInlinksPage = inlinks.filter(item => item.link === itemPage.url)
                var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === itemPage.url)
                var filterObjErrors = []
                var counterErrUrl = 0
                filterObjErrorsFilter.forEach(ele => {
                  if(ele.errors && ele.errors.length) {
                    ele.errors.forEach(eleCounter => {
                      counterErrUrl += eleCounter.counter
                      // if(!itemPage.url.includes('.xml') && itemPage.status === 200) {
                      //   this.totalErrors += eleCounter.counter
                      // }
                    })
                  }
                })
                filterObjErrors.push({counter: counterErrUrl})
                var urlcrawl = new URL(this.siteHomepage)
                var urlProtocol = urlcrawl.protocol
                var URLdomainFound = urlcrawl.host
                var URLwwwFound = ''
                var URLwithoutWWWFound = ''
                if(URLdomainFound.includes('www.')) {
                  URLwwwFound = urlProtocol + '//' + URLdomainFound
                  URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound.slice(4)
                } else {
                  URLwwwFound = urlProtocol + '//' + 'www.'+URLdomainFound
                  URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound
                }

                if(URLactual.pathname === '/' && filterHomePage.length === 0) {
                  var filterInlinksPageHome = inlinks.filter(item => item.link === this.siteHomepage || item.link === this.siteHomepage+ '/' || item.link === URLwwwFound || item.link === URLwithoutWWWFound || item.link === URLwwwFound + '/' || item.link === URLwithoutWWWFound + '/')
                  this.itemsPagesOverview.unshift({
                    id: 0,
                    selected: false,
                    page: 'home',
                    title: itemPage.title ? itemPage.title : 'home',
                    cTitle: itemPage.title ? itemPage.title : '',
                    pro: true,
                    url: 'Homepage',
                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                    inlinks: filterInlinksPageHome.length,
                    inlinksList: filterInlinksPageHome,
                    errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                    name: 'a',
                    cUrl: itemPage.url,
                    status: itemPage.status,
                    redirectTo: itemPage.redirectTo
                    // exist: true,
                  })
                } else if(URLactual.pathname === '/' && filterHomePage.length) {
                  var filterInlinksPageHome = inlinks.filter(item => item.link === this.siteHomepage || item.link === this.siteHomepage+ '/' || item.link === URLwwwFound || item.link === URLwithoutWWWFound || item.link === URLwwwFound + '/' || item.link === URLwithoutWWWFound + '/')
                  filterHomePage.forEach(ele => {
                    ele.status = itemPage.status
                    ele.title = itemPage.title ? itemPage.title : 'home'
                    ele.cTitle = itemPage.title ? itemPage.title : 'home'
                    ele.redirectTo = itemPage.redirectTo
                    ele.inlinks = filterInlinksPageHome.length
                  })
                } else if(filterPage.length === 0) {
                  var filterPro = proPagesArray.filter(ele => ele === itemPage.url)
                  idListPage += 1

                  if(filterPro.length) {
                    var filterP = arrayProPages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      arrayProPages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: true,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 200) {
                    if(itemPage.url.includes('.xml')) {
                      var filterP = array200XML.filter(ele => ele.cUrl === itemPage.url)
                      if(filterP.length) {
                        idListPage -= 1
                      } else {
                        array200XML.push({
                          id: idListPage,
                          selected: false,
                          page: URLactual.pathname,
                          title: itemPage.title ? itemPage.title : URLactual.pathname,
                          cTitle: itemPage.title ? itemPage.title : '',
                          pro: itemPage.pro ? true : false,
                          url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                          // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                          inlinks: filterInlinksPage.length,
                          inlinksList: filterInlinksPage,
                          errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                          name: URLactual.pathname,
                          cUrl: itemPage.url,
                          status: itemPage.status,
                          redirectTo: itemPage.redirectTo
                          // exist: true,
                        })
                      }
                    } else {
                      var filterP = array200Pages.filter(ele => ele.cUrl === itemPage.url)
                      if(filterP.length) {
                        idListPage -= 1
                      } else {
                        array200Pages.push({
                          id: idListPage,
                          selected: false,
                          page: URLactual.pathname,
                          title: itemPage.title ? itemPage.title : URLactual.pathname,
                          cTitle: itemPage.title ? itemPage.title : '',
                          pro: itemPage.pro ? true : false,
                          url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                          // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                          inlinks: filterInlinksPage.length,
                          inlinksList: filterInlinksPage,
                          errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                          name: URLactual.pathname,
                          cUrl: itemPage.url,
                          status: itemPage.status,
                          redirectTo: itemPage.redirectTo
                          // exist: true,
                        })
                      }
                    }
                  } else if(itemPage.status === 302) {
                    var filterP = array302Pages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      array302Pages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 301) {
                    var filterP = array301Pages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      array301Pages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 404) {
                    var filterP = array404Pages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      array404Pages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else if(itemPage.status === 'timeout') {
                    var filterP = arrayTimeoutPages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      arrayTimeoutPages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  } else {
                    var filterP = arrayOtherPages.filter(ele => ele.cUrl === itemPage.url)
                    if(filterP.length) {
                      idListPage -= 1
                    } else {
                      arrayOtherPages.push({
                        id: idListPage,
                        selected: false,
                        page: URLactual.pathname,
                        title: itemPage.title ? itemPage.title : URLactual.pathname,
                        cTitle: itemPage.title ? itemPage.title : '',
                        pro: itemPage.pro ? true : false,
                        url: this.domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : this.domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                        inlinks: filterInlinksPage.length,
                        inlinksList: filterInlinksPage,
                        errors: (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                        name: URLactual.pathname,
                        cUrl: itemPage.url,
                        status: itemPage.status,
                        redirectTo: itemPage.redirectTo
                        // exist: true,
                      })
                    }
                  }
                }
              }
            })

            var condicionParaOrdenar = (pageA, pageB) => {
              return pageB.inlinks - pageA.inlinks;
            }
            arrayProPages.sort(condicionParaOrdenar)
            array200Pages.sort(condicionParaOrdenar)
            array200XML.sort(condicionParaOrdenar)
            array302Pages.sort(condicionParaOrdenar)
            array301Pages.sort(condicionParaOrdenar)
            array404Pages.sort(condicionParaOrdenar)
            arrayTimeoutPages.sort(condicionParaOrdenar)
            arrayOtherPages.sort(condicionParaOrdenar)
            var incrementalIdPages = 0

            arrayProPages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })
            
            array200Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array302Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array200XML.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array301Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            array404Pages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            arrayTimeoutPages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            arrayOtherPages.forEach(ele => {
              incrementalIdPages += 1
              this.itemsPagesOverview.push({
                id: incrementalIdPages,
                selected: ele.selected,
                page: ele.page,
                title: ele.title,
                cTitle: ele.cTitle,
                pro: ele.pro,
                url: ele.url,
                inlinks: ele.inlinks,
                inlinksList: ele.inlinksList,
                errors: ele.errors,
                name: ele.name,
                cUrl: ele.cUrl,
                status: ele.status,
                redirectTo: ele.redirectTo
                // exist: true,
              })
            })

            // var condicionParaOrdenar1 = (pageA, pageB) => {
            //   return pageB.pro - pageA.pro;
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar1)

            // var condicionParaOrdenar2 = (pageA, pageB) => {
            //   // console.log(pageA.status,pageB.status)
            //   if(!pageA.cUrl.includes('.xml') && !pageB.cUrl.includes('.xml')) {
            //     return pageA.status - pageB.status;
            //   }
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar2)

            // var condicionParaOrdenar3 = (pageA, pageB) => {
            //   // console.log(pageA.status,pageB.status)
            //   if(pageA.cUrl.includes('.xml') && pageB.cUrl.includes('.xml')) {
            //     return pageA.status - pageB.status;
            //   }
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar3)

            // var condicionParaOrdenar4 = (pageA, pageB) => {
            //   // if(!pageB.pro && !pageA.pro) {
            //   if(!pageB.pro && !pageA.pro) {
            //     // if((pageB.status === 200 && pageA.status === 200) && (!pageB.cUrl.includes('.xml') && !pageA.cUrl.includes('.xml'))) {
            //     if((pageA.status === 200 && pageB.status === 200) && (!pageA.cUrl.includes('.xml') && !pageB.cUrl.includes('.xml'))) {
            //       // console.log('first')
            //       return pageB.inlinks - pageA.inlinks
            //     }
            //   }
            // }
            // this.itemsPagesOverview.sort(condicionParaOrdenar4)
          }
        })
        })
      }
    },
    logIdSite(val) {
      // console.log(val)
      // this.idsite = val
      this.showOpen = true
    },
    logIdSiteName(val) {
      // this.idsite = val
      this.showOpenSiteName = true
    },
    logIdSiteDomain(val) {
      // this.idsite = val
      this.showOpenSiteDomain = true
    },
    logIdComingSoon(val) {
      this.showOpenComingSoon = true
    },
    getError() {
      this.$router.push({
        name: 'view-errors',
        params: {
          id: this.$route.params.id, imgSite: this.siteObj[0].imgSite, name: this.siteObj[0].name, url: this.siteObj[0].domain, project: this.$route.params.project, own: this.siteObj[0].own
        },
      })
    },
    goToLicense() {
      this.$router.push({ name: 'memberships', params: { idsite: this.siteObj[0] } })
    },
    goToStatus(payload) {
      // console.log(payload)
      if(payload === 'active') {
        this.logIdArchive(this.siteObj[0])
      } else if(payload === 'archive') {
        this.logIdActive(this.siteObj[0])
      }
    },
    logIdArchive(val) {
      this.idsite = val
      this.showOpenArchiveSite = true
    },
    logIdActive(val) {
      this.idsite = val
      this.showOpenActiveSite = true
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Key copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy key!',
          icon: 'BellIcon',
          variant: 'danger',
        },
      })
    },
    chargeCrawl() {
    },
    getCrawlEvents() {
    },
    observerCheckbox(payload) {
      if(!this.processingFiltered){
        if(this.selected.length === this.itemsPagesOverview.length) {
          this.$refs.selectableTable.clearSelected()
          setTimeout(() => {
              this.$refs.selectableTable.selectRow(payload.index)
          }, 100)
        // } 
        // else if(this.selected.length === 1) {
        //   this.$refs.selectableTable.selectAllRows()
        //   setTimeout(() => {
        //       this.$refs.selectableTable.selectRow(payload.index)
        //   }, 100)
        } else {
          if(payload.rowSelected) {
            this.$refs.selectableTable.selectRow(payload.index)
          } else {
            this.$refs.selectableTable.unselectRow(payload.index)
          }
        }
      }
    },
    onRowSelected(items) {
      if(!this.processingFiltered) {
        this.selected = items
        // if(this.selected.length === this.itemsPagesOverview.length - 1) {
          // var idIndex = 0
          // this.itemsPagesOverview.forEach(el => {
          //   var filterNonSelect = this.selected.filter(it => it.id === el.id)
          //   if(filterNonSelect.length === 0) {
          //     idIndex = el.id
          //   } else {
          //     this.$refs.selectableTable.unselectRow(el.index)
          //   }
          // })
          // this.$refs.selectableTable.clearSelected()
          // setTimeout(() => {
          //   this.$refs.selectableTable.selectRow(idIndex)
          // }, 100)
        // }

        setTimeout(() => {
          this.itemsPagesOverviewFiltered = []
          this.rowsContentFiltered = []
          this.rowsErrorsFiltered = []
          this.selected.forEach(ele => {
            var itemsPagesOverviewFilteredAtomic = this.itemsPagesOverview.filter(item => item.cUrl === ele.cUrl)

            if(itemsPagesOverviewFilteredAtomic.length) {
              itemsPagesOverviewFilteredAtomic.forEach(element => {
                var filterPageOverview = this.itemsPagesOverviewFiltered.filter(it => it.cUrl === element.cUrl)
                if(!filterPageOverview.length) {
                  this.itemsPagesOverviewFiltered.push(element)
                }
              })
            }
          })

          if(this.itemsPagesOverviewFiltered.length) {
            this.selectedFilteredPages = this.itemsPagesOverviewFiltered
            // this.optionsFiltered
          } else {
            this.selectedFilteredPages = []
            this.$refs.selectableTable.clearSelected()
          }
        }, 100)
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },
  },
}
</script>

<style>
.eyeBtn {
    color: rgb(198, 198, 198)
}
.eyeBtn:hover {
    color: #A7AAB9 !important;
}
.link_claim:hover {
  color: #a7aab9 !important;
}
.tablePagesErrors th::before {
  display: none !important;
}
.tablePagesErrors th::after {
  display: none !important;
}
.tablePagesErrors .vgt-global-search__actions {
  margin: auto;
  width: 100%;
}
.tablePagesErrors .vgt-wrap__actions-footer {
  border: none;
}
thead .vgt-checkbox-col {
  width: 10px !important;
  max-width: 20px !important;
}
tbody .vgt-checkbox-col {
  width: 10px !important;
  max-width: 20px !important;
}
thead .vgt-checkbox-col input {
  display: none;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* margin: 20px !important; */
}
.vgt-table.bordered th {
  background: #fff !important;
  border: 1px solid transparent !important;
  margin-top: 20px !important;
  /* font-size: 16px !important; */
  /* color: #181E38 !important; */
}
.vgt-left-align {
    border: 1px solid transparent !important;
}
.vgt-row-header
.triangle {
    display: none !important;
}
.thTableSitesShortOverviewErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 50px !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: .75em .90em .75em 1.25em !important;
}
.tdTableSitesShortOverviewErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 50px !important;
  padding: .10em .90em !important;
  /* text-align: right; */
}
.thTableSitesShortOverviewErrorsPro {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 40px !important;
  max-width: 40px !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: .75em .90em .75em 1.25em !important;
}
.tdTableSitesShortOverviewErrorsPro {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 40px !important;
  max-width: 40px !important;
  padding: .10em 0em !important;
  text-align: center;
  /* text-align: right; */
}
.thTableSitesPageShortOverviewEdit {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 300px !important;
  max-width: 490px !important;
  font-weight: 300;
}
.tdTableSitesPageShortOverviewEdit {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  width: 300px !important;
  max-width: 490px !important;
  text-overflow: ellipsis !important;
  padding: .10em .90em !important;
}
.errors-card-stonk
.card-body {
  margin: auto !important;
  text-align: center !important;
}
.item-dropdown-action-sites:hover {
  background: none !important;
  background-color: none !important;
  color: #000 !important;
}
.dropdown-sites button {
  padding: 0px !important;
}
.dropdown-sites button:hover {
  background: transparent !important;
}
#modalHistory-lg___BV_modal_body_ {
  padding: 0px;
}
/* .linkURL {
  display: none !important;
} */
table.vgt-table tr.clickable .linkURL {
  display: inline !important;
  padding-top: 12px !important;
}
table.vgt-table tr.clickable:hover .linkURL svg {
  /* color: rgb(170, 170, 170) !important; */
  /* fill: #000 !important; */
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
/* table.vgt-table tr.clickable:hover .vgt-checkbox-col input {
  margin-left: 20px;
} */
.vgt-checkbox-col input {
  margin-left: 8px;
}
.tabsViewErrors
.nav-tabs
.nav-link.active {
  color: #232C51 !important;
  font-family: 'Hurme Geometric Sans 3';
  font-weight: 700;
  padding-bottom: 4px;
  font-size: 19px;
  background: none !important;
}
.tabsViewErrors
.nav-tabs
.nav-link.active::after {
  background: transparent linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
.tabsViewErrors
.nav-tabs
.nav-link {
  color: #232C51 !important;
  /* font-family: ; */
  padding-bottom: 0px;
  font-size: 19px;
}
.allSelectedRows1 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #8c8c8c !important;
  color: #fff !important;
}
.allSelectedRows2 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #be35ff !important;
  color: #fff !important;
}
.eyeBtn {
  color: rgb(198, 198, 198)
}
.eyeBtn:hover {
  color: #A7AAB9 !important;
}
.listNumberErrors th.vgt-left-align {
  display: none !important;
}
.btn-fix-all-errors:hover {
  border: 1px solid transparent !important;
}
.refreshIcon {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  width: 18px;
  height: 20px;
  /* margin:-60px 0 0 -60px; */
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
.listExternalPages .card-body {
  padding: 0px !important;
}
.dropdown-edit-site {
  list-style-type: none !important;
}
.dropdown-edit-site::marker {
  display: none !important;
  color: transparent !important;
}
.dropdown-edit-site .dropdown-menu {
  position: absolute;
  top: -2px;
  width: 125px !important;
  padding: 1px;
}
.dropdown-edit-site .dropdown-menu li a {
  padding: 10px;
  text-align: center !important;
}
.dropdown-edit-site .show {
  background: transparent !important;
  border: 1px solid transparent !important;
}
.dropdown-edit-site .dropdown-toggle::after {
  display: none !important
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.filteredPages .vs__selected {
  background: #E3E6F2 !important;
  border: 1px solid #CFD3E5 !important;
  color: #656B85 !important;
  border-radius: 8px !important;
}
.filteredPages .vs__selected-options .vs__search {
  min-width: 40px !important;
}
.filteredPages li {
  padding: 5px !important;
}
.filteredPages .vs__selected .vs__deselect {
  display: none !important;
}
.custom-control-gray label::before {
  border-color: #aeaeae !important;
  background-color: #aeaeae !important;
}
input[class="custom-control-input"] + label::before {
  border-color: #656b85 !important;
  /* border: 1px solid #656b85 !important; */
  /* background-color: #656b85 !important; */
  /* -webkit-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1) !important; */
  /* -moz-box-shadow: 0px 0px 0px 1px rgba(255,0,0,1) !important; */
  /* box-shadow: 0px 0px 0px 1px rgba(255,0,0,1) !important; */
}
.tablePagesErrors th::before {
  display: none !important;
}
.tablePagesErrors th::after {
  display: none !important;
}
.tablePagesErrors .vgt-global-search__actions {
  margin: auto;
  width: 100%;
}
.tablePagesErrors .vgt-wrap__actions-footer {
  border: none;
}
thead .vgt-checkbox-col {
  width: 10px !important;
  max-width: 20px !important;
}
tbody .vgt-checkbox-col {
  width: 10px !important;
  max-width: 20px !important;
}
thead .vgt-checkbox-col input {
  display: none;
}
.thTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  font-weight: 300;
}
.tdTableSites {
  background: transparent !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #DCDCF2 !important;
  /* margin: 20px !important; */
}
.vgt-table.bordered th {
  background: #fff !important;
  border: 1px solid transparent !important;
  margin-top: 20px !important;
  /* font-size: 17px !important; */
  /* color: #181E38 !important; */
}
.vgt-left-align {
    border: 1px solid transparent !important;
}
.vgt-row-header
.triangle {
    display: none !important;
}
.tableEditSite {
  table-layout: auto;
  display: table;
  width: 100%;
}
.thTableSitesSelectShortOverviewErrorsEditSite {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 51px !important;
  max-width: 53px !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: 0px !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
}
.tdTableSitesSelectShortOverviewErrorsEditSite {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 51px !important;
  max-width: 53px !important;
  padding: 0px !important;
  /* text-align: right; */
  font-size: 17px !important;
}
.thTableSitesShortOverviewErrors {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: .75em .90em .75em 1.25em !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
}
.tdTableSitesShortOverviewErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  padding: .10em .90em !important;
  text-align: center !important;
  font-size: 17px !important;
}
.thTableSitesShortOverviewErrorsPro {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  font-weight: 300;
  /* text-align: right !important; */
  padding: .75em .90em .75em 1.25em !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
}
.tdTableSitesShortOverviewErrorsPro {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* padding: 0px !important; */
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
  vertical-align: middle !important;
  padding: .10em 0em !important;
  text-align: center;
  /* text-align: right; */
  font-size: 17px !important;
}
.thTableSitesPageShortOverviewEdit {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  /* width: 100% !important;
  max-width: 100% !important; */
  width: 720px !important;
  max-width: 720px !important;
  min-width: 320px !important;
  font-weight: 300;
  font-size: 17px !important;
  padding: .75em 0em .75em .75em !important;
  text-transform: capitalize !important;
}
.tdTableSitesPageShortOverviewEdit {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  /* width: 100% !important;
  max-width: 100% !important; */
  width: 720px !important;
  max-width: 720px !important;
  min-width: 320px !important;
  text-overflow: ellipsis !important;
  padding: .10em .90em !important;
  font-size: 17px !important;
  line-height: normal;
}
.thTableSitesPageShortOverviewErrors {
  background: #fff !important;
  border: 1px solid transparent !important;
  color: #A7AAB9 !important;
  font-size: 15px !important;
  width: 720px !important;
  max-width: 720px !important;
  min-width: 320px !important;
  font-weight: 300;
  font-size: 17px !important;
  padding: .75em .90em .75em 1.25em !important;
  text-transform: capitalize !important;
}
.tdTableSitesPageShortOverviewErrors {
  background: transparent !important;
  border: 1px solid transparent !important;
  /* border-bottom: 1px solid #dcdfe6 !important; */
  width: 720px !important;
  max-width: 720px !important;
  min-width: 320px !important;
  text-overflow: ellipsis !important;
  padding: .40em 12px .40em .90em !important;
  font-size: 17px !important;
  line-height: normal;

  /* display: inline-block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; */
}
.tdStatusTableErrors {
  text-align: center !important;
  vertical-align: middle !important;
}
.tdHistoryTableErrors {
  text-align: center !important;
  vertical-align: middle !important;
}
table.vgt-table.tableErrors {
  font-size: 17px !important;
}
table.vgt-table.tableContent {
  font-size: 17px !important;
}
.errors-card-stonk
.card-body {
  margin: auto !important;
  text-align: center !important;
}
.item-dropdown-action-sites:hover {
  background: none !important;
  background-color: none !important;
  color: #000 !important;
}
.dropdown-sites button {
  padding: 0px !important;
}
.dropdown-sites button:hover {
  background: transparent !important;
}
#modalHistory-lg___BV_modal_body_ {
  padding: 0px;
}
/* .linkURL {
  display: none !important;
} */
table.vgt-table tr.clickable .linkURL {
  display: inline !important;
  padding-top: 12px !important;
}
table.vgt-table tr:hover .linkURL svg {
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
table.b-table-selectable tr.b-table-row-selected:hover .linkURL svg {
  /* color: rgb(170, 170, 170) !important; */
  /* fill: #000 !important; */
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
table.b-table-selectable tr:hover .linkURL svg {
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
.externalLinkContent:hover {
  color: #7838FF !important;
}
.externalLinkContent:hover .linkURL svg {
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
.card-header:hover .headerExternalLink .ExternalLinkURLHeader svg {
/* .headerExternalLink:hover .ExternalLinkURLHeader svg { */
  filter: invert(63%) sepia(20%) saturate(9%) hue-rotate(317deg) brightness(98%) contrast(100%);
}
/* table.vgt-table tr.clickable:hover .vgt-checkbox-col input {
  margin-left: 20px;
} */
.vgt-checkbox-col input {
  margin-left: 8px;
}
.tabsViewErrors
.nav-tabs
.nav-link.active {
  color: #232C51 !important;
  font-family: 'Hurme Geometric Sans 3';
  font-weight: 700;
  padding-bottom: 4px;
  font-size: 19px;
  background: none !important;
}
.tabsViewErrors
.nav-tabs
.nav-link.active::after {
  background: transparent linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
.tabsViewErrors
.nav-tabs
.nav-link {
  color: #232C51 !important;
  /* font-family: ; */
  padding-bottom: 0px;
  font-size: 19px;
}
.allSelectedRows1 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #8c8c8c !important;
  color: #fff !important;
}
.allSelectedRows2 .vgt-checkbox-col input:checked {
  /* background-color: red !important; */
  accent-color: #be35ff !important;
  color: #fff !important;
}
.eyeBtn {
  color: rgb(198, 198, 198)
}
.eyeBtn:hover {
  color: #A7AAB9 !important;
}
.listNumberErrors th.vgt-left-align {
  display: none !important;
}
.btn-fix-all-errors:hover {
  border: 1px solid transparent !important;
}
@media (min-width: 1200px) {
  .card-domain-site-name {
    max-width: 350px !important;
    /* width: 350px !important; */
  }
  .cards-no-domain-site-name {
    max-width: 192px !important;
    /* width: 192px !important; */
  }
  .crawl-info-edit-site-md {
    display: none !important;
  }
  .row-no-container-domain-name-sm {
    display: none !important;
  }
  /* .row-container-domain-name {
    margin-right: 0px !important;
  } */
  .row-md-container-edit-site {
    display: none !important;
  }
}
@media (max-width: 1199px) {
  .row-xl-container-edit-site {
    display: none !important;
  }
  .crawl-info-edit-site-xl {
    display: none !important;
  }
  .row-container-domain-name {
    /* padding-left: 55px !important; */
    max-width: 100% !important;
    width: 100% !important;
  }
  .row-container-domain-name .align-self-baseline {
    padding-right: 0px !important;
  }
  .card-domain-site-name {
    max-width: 100% !important;
    width: 100% !important;
  }
  .row-no-container-domain-name {
    max-width: 100% !important;
    width: 100% !important;
    padding-right: 0px !important;
  }
  .row-no-container-domain-name .align-self-baseline {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }
  .cards-no-domain-site-name {
    max-width: 100% !important;
    width: 100% !important;
  }
  .row-no-container-domain-name-sm {
    display: none !important;
  }

  .row-md-container-edit-site .img {
    width: 20% !important;
    max-width: 20% !important;
    flex: 0 0 20%;
  }
  .row-md-container-edit-site .desc {
    width: 80% !important;
    max-width: 80% !important;
    flex: 0 0 80%;
  }
}
@media (max-width: 1052px) {
  .row-container-domain-name {
    padding-left: 10px !important;
  }
}
@media (max-width: 992px) {
  .row-container-domain-name {
    padding-left: 20px !important;
  }
}
@media (max-width: 950px) {
  .row-container-domain-name {
    padding-left: 30px !important;
  }
}
@media (max-width: 894px) {
  .row-container-domain-name {
    padding-left: 40px !important;
  }
}
@media (max-width: 838px) {
  .row-container-domain-name {
    padding-left: 50px !important;
  }
}
@media (max-width: 794px) {
  .row-container-domain-name {
    padding-left: 60px !important;
  }
}
@media (max-width: 684px) {
  .row-container-domain-name {
    padding-left: 70px !important;
  }
}
@media (max-width: 634px) {
  .row-container-domain-name {
    padding-left: 80px !important;
  }
}
@media (max-width: 574px) {
  .row-container-domain-name {
    padding-left: 90px !important;
  }
}
@media (max-width: 524px) {
  .row-container-domain-name {
    padding-left: 100px !important;
  }
}
@media (max-width: 499px) {
  .row-container-domain-name {
    padding-left: 0px !important;
  }
  .row-md-container-edit-site .img {
    display: none;
  }
  .row-md-container-edit-site .desc {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
  }
}
@media (max-width: 768px) {
  .col-md-container-edit-site {
    max-width: 50% !important;
    flex: 0 0 50% !important;
    padding-right: 5px !important;
  }
  .col-md-container-edit-site .row-no-container-domain-name {
    max-width: 100% !important;
    width: 100% !important;
  }
  .row-container-domain-name {
    max-width: 100% !important;
    width: 100% !important;
  }
  .row-no-container-domain-name-md {
    display: none !important;
  }
  .row-no-container-domain-name-sm {
    max-width: 50% !important;
    width: 50% !important;
    display: flex !important;
    padding-top: 13px;
    padding-left: 13px;
  }
  /* .row-no-container-domain-name {
    max-width: 50% !important;
    width: 50% !important;
  }
  .cards-no-domain-site-name {
    max-width: 100% !important;
    width: 100% !important;
  } */
  .row-container-domain-name .align-self-baseline {
    margin-right: 0px !important;
  }
}
@media (max-width: 608px) {
  .row-container-domain-name {
    max-width: 100% !important;
    width: 100% !important;
  }
}
@media (min-width: 500px) and (max-width: 768px) {
  /* .row-container-domain-name {
    max-width: 60% !important;
    width: 60% !important;
  } */
  .row-no-container-domain-name {
    max-width: 50% !important;
    width: 50% !important;
  }
  .cards-no-domain-site-name {
    max-width: 100% !important;
    width: 100% !important;
  }
}
@media (max-width: 499px) {
  .col-md-container-edit-site {
    max-width: 100% !important;
    flex: 0 0 100% !important;
    padding-right: 0px !important;
  }
  .col-md-container-edit-site .row-no-container-domain-name {
    max-width: 100% !important;
    width: 100% !important;
  }
  .row-md-container-edit-site {
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 0px !important;
  }
  .row-container-img {
    display: none !important;
  }
  .row-container-domain-name {
    max-width: 100% !important;
    width: 100% !important;
    padding-right: 0px !important;
  }
  .row-no-container-domain-name {
    max-width: 100% !important;
    width: 100% !important;
  }
  .cards-no-domain-site-name {
    max-width: 100% !important;
    width: 100% !important;
  }
  .row-no-container-domain-name-sm {
    max-width: 100% !important;
    width: 100% !important;
    display: flex !important;
    padding-top: 13px;
    padding-left: 13px;
  }
  .card-domain-site-name {
    margin-bottom: 10px !important;
  }
  .cards-no-domain-site-name {
    margin-bottom: 10px !important;
  }
}
</style>